import { ImmutableClass } from './ImmutableClass';
import { List, Map } from 'immutable';

export function compareDiagnosisDefinition ( a: DiagnosisDefinition, b: DiagnosisDefinition )
{
    if ( a.Name < b.Name )
    {
      return -1;
    }
    else if ( a.Name > b.Name )
    {
      return 1;
    }
    else
    {
        return 0;
    }
  }

export class DiagnosisDefinition extends ImmutableClass
{
    public static fromJSON ( json: any ) : DiagnosisDefinition[]
    {
        let diagnoses: DiagnosisDefinition [ ] = [ ];
        for ( let diagnosis of json )
        {
            diagnoses.push ( new DiagnosisDefinition ( ).setId ( diagnosis.id ).setName ( diagnosis.name ) );
        }
        return diagnoses;
    }

    public static toJSON ( diagnoses: List<DiagnosisDefinition> ) : any
    {
        let res = [];
        if ( diagnoses != null )
        {
            for ( let diagnosis of diagnoses.toArray ( ) )
            {
                res.push ( { id: diagnosis.Id, name: diagnosis.Name } );
            }
        }
        return res;
    }

    /**
     * Constructor 
     * @param i existing map of attributes
     */
    public constructor ( i: Map<string, any> = undefined )
    {
        super ( i );
    }

    public get Id ( ): string
    {
        return <string>this.i.get ( 'id' );
    }

    public setId ( id: string ): DiagnosisDefinition
    {
        return new DiagnosisDefinition ( this.i.set ( 'id', id ) );
    }

    public get Name ( ): string
    {
        return <string>this.i.get ( 'name' );
    }

    public setName ( name: string ): DiagnosisDefinition
    {
        return new DiagnosisDefinition ( this.i.set ( 'name', name ) );
    }
}