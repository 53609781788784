import { ImmutableClass } from './ImmutableClass';
import { List } from 'immutable';

export function compareSymptom ( a: Symptom, b: Symptom )
{
    if ( a.Name < b.Name )
    {
      return -1;
    }
    else if ( a.Name > b.Name )
    {
      return 1;
    }
    else
    {
        return 0;
    }
  }

export class Symptom extends ImmutableClass
{
    public static fromJSON ( symptom: any ) : Symptom
    {
        if ( symptom )
        {
            return new Symptom ( ).setId ( symptom.id ).setName ( symptom.name ).setDefinition ( symptom.definition ).setSynonyms ( List ( symptom.synonyms ) );
        }
        else
        {
            return null;
        }
    }

    public static fromJSONForList ( json: any ) : Symptom[]
    {
        let symptoms: Symptom [ ] = [ ];
        for ( let symptom of json )
        {
            symptoms.push ( Symptom.fromJSON ( symptom ) );
        }
        return symptoms;
    }

    public static toJSON ( symptom: Symptom ) : any
    {
        if ( symptom == null )
        {
            return null;
        }
        else
        {
             return { id: symptom.Id, name: symptom.Name, synonyms: symptom.Synonyms.toArray ( ), definition: symptom.Definition };
        }
    }

    public static toJSONforList ( symptoms: List<Symptom> ) : any
    {
        let res = [];
        if ( symptoms != null )
        {
            for ( let symptom of symptoms.toArray ( ) )
            {
                res.push ( Symptom.toJSON ( symptom ) );
            }
        }
        return res;
    }

    /**
     * Constructor 
     * @param i existing map of attributes
     */
    public constructor ( i: any = undefined )
    {
        super ( i || { id: null, name: null, synonyms: null, definition: null } );
    }

    public get Id ( ): string
    {
        return <string>this.i.get ( 'id' );
    }

    public setId ( id: string ): Symptom
    {
        return new Symptom ( this.i.set ( 'id', id ) );
    }

    public get Name ( ): string
    {
        return <string>this.i.get ( 'name' );
    }

    public setName ( name: string ): Symptom
    {
        return new Symptom ( this.i.set ( 'name', name ) );
    }

    public get Synonyms ( ): List<string>
    {
        return <List<string>>this.i.get ( 'synonyms' );
    }

    public setSynonyms ( synonyms: List<string> ): Symptom
    {
        return new Symptom ( this.i.set ( 'synonyms', synonyms ) );
    }

    public get Definition ( ): string
    {
        return <string>this.i.get ( 'definition' );
    }

    public setDefinition ( definition: string ): Symptom
    {
        return new Symptom ( this.i.set ( 'definition', definition ) );
    }
}