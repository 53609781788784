import { ImmutableClass } from './ImmutableClass';
import { Map } from 'immutable';

export class Site extends ImmutableClass
{
    /**
     * Constructor 
     * @param i existing map of attributes
     */
    public constructor ( i: Map<string, any> = undefined )
    {
        super ( i );
    }

    public get Id ( ): string
    {
        return <string>this.i.get ( 'site' );
    }

    public setId ( id: string ): Site
    {
        return new Site ( this.i.set ( 'site', id ) );
    }

    public get Name ( ): string
    {
        return <string>this.i.get ( 'name' );
    }

    public setName ( name: string ): Site
    {
        return new Site ( this.i.set ( 'name', name ) );
    }

    public get Telephone ( ): string
    {
        return <string>this.i.get ( 'telephone' );
    }

    public setTelephone ( telephone: string ): Site
    {
        return new Site ( this.i.set ( 'telephone', telephone ) );
    }

    public get Email ( ): string
    {
        return <string>this.i.get ( 'email' );
    }

    public setEmail ( email: string ): Site
    {
        return new Site ( this.i.set ( 'email', email ) );
    }

    public get Address1 ( ): string
    {
        return <string>this.i.get ( 'address1' );
    }

    public setAddress1 ( address1: string ): Site
    {
        return new Site ( this.i.set ( 'address1', address1 ) );
    }

    public get Address2 ( ): string
    {
        return <string>this.i.get ( 'address2' );
    }

    public setAddress2 ( address2: string ): Site
    {
        return new Site ( this.i.set ( 'address2', address2 ) );
    }

    public get County ( ): string
    {
        return <string>this.i.get ( 'county' );
    }

    public setCounty ( county: string ): Site
    {
        return new Site ( this.i.set ( 'county', county ) );
    }

    public get PostCode ( ): string
    {
        return <string>this.i.get ( 'postCode' );
    }

    public setPostCode ( postCode: string ): Site
    {
        return new Site ( this.i.set ( 'postCode', postCode ) );
    }
    
    public get Group ( ): string
    {
        return <string>this.i.get ( 'group' );
    }

    public setGroup ( group: string ): Site
    {
        return new Site ( this.i.set ( 'group', group ) );
    }
}