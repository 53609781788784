import { Action } from '@ngrx/store';
import { Site } from '../utils/Site';
import { User } from '../utils/User';
import { Central } from '../utils/Central';
import { DrugDefinition } from '../utils/DrugDefinition';
import { Symptom } from '../utils/Symptom';
import { Role } from '../utils/Role';
import { DiagnosisDefinition } from '../utils/DiagnosisDefinition';
import { CurrentUser } from '../utils/CurrentUser';
import { Group } from '../utils/Group';

/**
 * Generate UUID
 */
export const GENERATE_UUIDS = 'shared.uuids.generate';
export class GenerateUUIDs implements Action 
{ 
    readonly type = GENERATE_UUIDS;
    constructor ( )
    {
        // Null.
    } 
}

export const GENERATE_UUIDS_SUCCESS = 'shared.uuids.generate.success';
export class GenerateUUIDsSuccess implements Action 
{ 
    readonly type = GENERATE_UUIDS_SUCCESS; 
    constructor ( public uuids: string[] )
    {
        // Null.
    }
}

export const GENERATE_UUIDS_FAILURE = 'shared.uuids.generate.failure';
export class GenerateUUIDsFailure implements Action 
{ 
    readonly type = GENERATE_UUIDS_FAILURE; 
    constructor ( public statusCode: number, public statusText: string )
    {
        // Null.
    }
}

/**
 * Consume UUID
 */
export const CONSUME_UUID = 'shared.uuids.consume';
export class ConsumeUUID implements Action 
{ 
    readonly type = CONSUME_UUID;
    constructor ( )
    {
        // Null.
    } 
}

/**
 * Get central and sites
 */
export const GET_CENTRAL_SITES = 'shared.central.sites.get';
export class GetCentralSites implements Action 
{ 
    readonly type = GET_CENTRAL_SITES;
    constructor ( )
    {
        // Null.
    } 
}

export const GET_CENTRAL_SITES_SUCCESS = 'shared.central.sites.get.success';
export class GetCentralSitesSuccess implements Action 
{ 
    readonly type = GET_CENTRAL_SITES_SUCCESS; 
    constructor ( public rootGroupId: string, public sites: Site[] )
    {
        // Null.
    }
}

export const GET_CENTRAL_SITES_FAILURE = 'shared.central.get.failure';
export class GetCentralSitesFailure implements Action 
{ 
    readonly type = GET_CENTRAL_SITES_FAILURE; 
    constructor ( public statusCode: number, public statusText: string )
    {
        // Null.
    }
}

/**
 * Create site
 */
export const CREATE_SITE = 'shared.sites.create';
export class CreateSite implements Action 
{ 
    readonly type = CREATE_SITE;
    constructor ( public name: string, public telephone: string, public email: string,
                  public address1: string, public address2: string, public county: string, 
                  public postCode: string, public logo: File )
    {
        // Null.
    } 
}

export const CREATE_SITE_SUCCESS = 'shared.sites.create.success';
export class CreateSiteSuccess implements Action 
{ 
    readonly type = CREATE_SITE_SUCCESS; 
    constructor ( public site: Site )
    {
        // Null.
    }
}

export const CREATE_SITE_FAILURE = 'shared.sites.create.failure';
export class CreateSiteFailure implements Action 
{ 
    readonly type = CREATE_SITE_FAILURE; 
    constructor ( public statusCode: number, public statusText: string )
    {
        // Null.
    }
}

/**
 * Update site
 */
export const UPDATE_SITE = 'shared.sites.update';
export class UpdateSite implements Action 
{ 
    readonly type = UPDATE_SITE;
    constructor ( public group: string, public id: string, public name: string, 
                  public telephone: string, public email: string, public address1: string, 
                  public address2: string, public county: string, public postCode: string, public logo: File )
    {
        // Null.
    } 
}

export const UPDATE_SITE_SUCCESS = 'shared.sites.update.success';
export class UpdateSiteSuccess implements Action 
{ 
    readonly type = UPDATE_SITE_SUCCESS; 
    constructor ( public site: Site )
    {
        // Null.
    }
}

export const UPDATE_SITE_FAILURE = 'shared.sites.update.failure';
export class UpdateSiteFailure implements Action 
{ 
    readonly type = UPDATE_SITE_FAILURE; 
    constructor ( public statusCode: number, public statusText: string )
    {
        // Null.
    }
}

/**
 * Delete site
 */
export const DELETE_SITE = 'shared.sites.delete';
export class DeleteSite implements Action 
{ 
    readonly type = DELETE_SITE;
    constructor ( public id: string )
    {
        // Null.
    } 
}

export const DELETE_SITE_SUCCESS = 'shared.sites.delete.success';
export class DeleteSiteSuccess implements Action 
{ 
    readonly type = DELETE_SITE_SUCCESS; 
    constructor ( public id: string )
    {
        // Null.
    }
}

export const DELETE_SITE_FAILURE = 'shared.sites.delete.failure';
export class DeleteSiteFailure implements Action 
{ 
    readonly type = DELETE_SITE_FAILURE; 
    constructor ( public statusCode: number, public statusText: string )
    {
        // Null.
    }
}

/**
 * Get roles
 */
export const GET_ROLES = 'shared.roles.get';
export class GetRoles implements Action 
{ 
    readonly type = GET_ROLES;
    constructor ( )
    {
        // Null.
    } 
}

export const GET_ROLES_SUCCESS = 'shared.roles.get.success';
export class GetRolesSuccess implements Action 
{ 
    readonly type = GET_ROLES_SUCCESS; 
    constructor ( public roles: Role[], public app: Role, public system: Role, public central: Role, 
                  public medic_ci: Role, public medic_pi: Role, public rater: Role, public pharmacy: Role )
    {
        // Null.
    }
}

export const GET_ROLES_FAILURE = 'shared.roles.get.failure';
export class GetRolesFailure implements Action 
{ 
    readonly type = GET_ROLES_FAILURE; 
    constructor ( public statusCode: number, public statusText: string )
    {
        // Null.
    }
}

/**
 * Get users
 */
export const GET_USERS = 'shared.users.get';
export class GetUsers implements Action 
{ 
    readonly type = GET_USERS;
    constructor ( )
    {
        // Null.
    } 
}

export const GET_USERS_SUCCESS = 'shared.users.get.success';
export class GetUsersSuccess implements Action 
{ 
    readonly type = GET_USERS_SUCCESS; 
    constructor ( public users: User[] )
    {
        // Null.
    }
}

export const GET_USERS_FAILURE = 'shared.users.get.failure';
export class GetUsersFailure implements Action 
{ 
    readonly type = GET_USERS_FAILURE; 
    constructor ( public statusCode: number, public statusText: string )
    {
        // Null.
    }
}

/**
 * Create user
 */
export const CREATE_USER = 'shared.user.create';
export class CreateUser implements Action 
{ 
    readonly type = CREATE_USER;
    constructor ( public email: string, public firstName: string, public lastName: string, public groups: Group[], public role: Role )
    {
        // Null.
    } 

    public generateRolesBody ( ): any[]
    {
        let out = [];
        out.push ( {id: this.role.Id, name: this.role.Name } );

        return out;
    }
}

export const CREATE_USER_SUCCESS = 'shared.user.create.success';
export class CreateUserSuccess implements Action 
{ 
    readonly type = CREATE_USER_SUCCESS; 
    constructor ( public user: User )
    {
        // Null.
    }
}

export const CREATE_USER_FAILURE = 'shared.user.create.failure';
export class CreateUserFailure implements Action 
{ 
    readonly type = CREATE_USER_FAILURE; 
    constructor ( public statusCode: number, public statusText: string )
    {
        // Null.
    }
}

/**
 * Update user
 */
export const UPDATE_USER = 'shared.user.update';
export class UpdateUser implements Action 
{ 
    readonly type = UPDATE_USER;
    constructor ( public userId: string, public email: string, public firstName: string, public lastName: string, public groups: Group[], public role: Role )
    {
        // Null.
    }

    public generateRolesBody ( ): any[]
    {
        let out = [];
        out.push ( {id: this.role.Id, name: this.role.Name } );

        return out;
    }
}

export const UPDATE_USER_SUCCESS = 'shared.user.update.success';
export class UpdateUserSuccess implements Action 
{ 
    readonly type = UPDATE_USER_SUCCESS; 
    constructor ( public user: User )
    {
        // Null.
    }
}

export const UPDATE_USER_FAILURE = 'shared.user.update.failure';
export class UpdateUserFailure implements Action 
{ 
    readonly type = UPDATE_USER_FAILURE; 
    constructor ( public statusCode: number, public statusText: string )
    {
        // Null.
    }
}

/**
 * Delete user
 */
export const DELETE_USER = 'shared.user.delete';
export class DeleteUser implements Action 
{ 
    readonly type = DELETE_USER;
    constructor ( public id: string )
    {
        // Null.
    } 
}

export const DELETE_USER_SUCCESS = 'shared.user.delete.success';
export class DeleteUserSuccess implements Action 
{ 
    readonly type = DELETE_USER_SUCCESS; 
    constructor ( public id: string )
    {
        // Null.
    }
}

export const DELETE_USER_FAILURE = 'shared.user.delete.failure';
export class DeleteUserFailure implements Action 
{ 
    readonly type = DELETE_USER_FAILURE; 
    constructor ( public statusCode: number, public statusText: string )
    {
        // Null.
    }
}

/**
 * Get user (self)
 */
export const GET_SELF = 'shared.self.get';
export class GetSelf implements Action 
{ 
    readonly type = GET_SELF;
    constructor ( public token: any )
    {
        // Null.
    } 
}

export const GET_SELF_SUCCESS = 'shared.self.get.success';
export class GetSelfSuccess implements Action 
{ 
    readonly type = GET_SELF_SUCCESS; 
    constructor ( public self: CurrentUser )
    {
        // Null.
    }
}

export const GET_SELF_FAILURE = 'shared.self.get.failure';
export class GetSelfFailure implements Action 
{ 
    readonly type = GET_SELF_FAILURE; 
    constructor ( public statusCode: number, public statusText: string )
    {
        // Null.
    }
}

/**
 * Send user password reset email
 */
export const SEND_PASSWORD_RESET_EMAIL = 'shared.user.password.reset';
export class SendPasswordResetEmail implements Action 
{ 
    readonly type = SEND_PASSWORD_RESET_EMAIL;
    constructor ( public userId: string )
    {
        // Null.
    } 
}

export const SEND_PASSWORD_RESET_EMAIL_SUCCESS = 'shared.user.password.reset.success';
export class SendPasswordResetEmailSuccess implements Action 
{ 
    readonly type = SEND_PASSWORD_RESET_EMAIL_SUCCESS; 
    constructor ( )
    {
        // Null.
    }
}

export const SEND_PASSWORD_RESET_EMAIL_FAILURE = 'shared.user.password.reset.failure';
export class SendPasswordResetEmailFailure implements Action 
{ 
    readonly type = SEND_PASSWORD_RESET_EMAIL_FAILURE; 
    constructor ( public statusCode: number, public statusText: string )
    {
        // Null.
    }
}

/**
 * Get diagnoses
 */
export const GET_DIAGNOSES = 'shared.diagnoses.get';
export class GetDiagnoses implements Action 
{ 
    readonly type = GET_DIAGNOSES;
    constructor ( public term: string )
    {
        // Null.
    } 
}

export const GET_DIAGNOSES_SUCCESS = 'shared.diagnoses.get.success';
export class GetDiagnosesSuccess implements Action 
{ 
    readonly type = GET_DIAGNOSES_SUCCESS; 
    constructor ( public diagnoses: DiagnosisDefinition[] )
    {
        // Null.
    }
}

export const GET_DIAGNOSES_FAILURE = 'shared.diagnoses.get.failure';
export class GetDiagnosesFailure implements Action 
{ 
    readonly type = GET_DIAGNOSES_FAILURE; 
    constructor ( public statusCode: number, public statusText: string )
    {
        // Null.
    }
}

/**
 * Get drugs
 */
export const GET_DRUGS = 'shared.drugs.get';
export class GetDrugs implements Action 
{ 
    readonly type = GET_DRUGS;
    constructor ( )
    {
        // Null.
    } 
}

export const GET_DRUGS_SUCCESS = 'shared.drugs.get.success';
export class GetDrugsSuccess implements Action 
{ 
    readonly type = GET_DRUGS_SUCCESS; 
    constructor ( public drugs: DrugDefinition[] )
    {
        // Null.
    }
}

export const GET_DRUGS_FAILURE = 'shared.drugs.get.failure';
export class GetDrugsFailure implements Action 
{ 
    readonly type = GET_DRUGS_FAILURE; 
    constructor ( public statusCode: number, public statusText: string )
    {
        // Null.
    }
}

/**
 * Create drug
 */
export const CREATE_DRUG = 'shared.drugs.create';
export class CreateDrug implements Action 
{ 
    readonly type = CREATE_DRUG;
    constructor ( public name: string, public chemicalName: string )
    {
        // Null.
    } 
}

export const CREATE_DRUG_SUCCESS = 'shared.drugs.create.success';
export class CreateDrugSuccess implements Action 
{ 
    readonly type = CREATE_DRUG_SUCCESS; 
    constructor ( public drug: DrugDefinition )
    {
        // Null.
    }
}

export const CREATE_DRUG_FAILURE = 'shared.drugs.create.failure';
export class CreateDrugFailure implements Action 
{ 
    readonly type = CREATE_DRUG_FAILURE; 
    constructor ( public statusCode: number, public statusText: string )
    {
        // Null.
    }
}

/**
 * Update drug
 */
export const UPDATE_DRUG = 'shared.drugs.update';
export class UpdateDrug implements Action 
{ 
    readonly type = UPDATE_DRUG;
    constructor ( public drug: DrugDefinition )
    {
        // Null.
    } 
}

export const UPDATE_DRUG_SUCCESS = 'shared.drugs.update.success';
export class UpdateDrugSuccess implements Action 
{ 
    readonly type = UPDATE_DRUG_SUCCESS; 
    constructor ( public drug: DrugDefinition )
    {
        // Null.
    }
}

export const UPDATE_DRUG_FAILURE = 'shared.drugs.update.failure';
export class UpdateDrugFailure implements Action 
{ 
    readonly type = UPDATE_DRUG_FAILURE; 
    constructor ( public statusCode: number, public statusText: string )
    {
        // Null.
    }
}

/**
 * Delete drug
 */
export const DELETE_DRUG = 'shared.drugs.delete';
export class DeleteDrug implements Action 
{ 
    readonly type = DELETE_DRUG;
    constructor ( public id: string )
    {
        // Null.
    } 
}

export const DELETE_DRUG_SUCCESS = 'shared.drug.delete.success';
export class DeleteDrugSuccess implements Action 
{ 
    readonly type = DELETE_DRUG_SUCCESS; 
    constructor ( public id: string )
    {
        // Null.
    }
}

export const DELETE_DRUG_FAILURE = 'shared.drugs.delete.failure';
export class DeleteDrugFailure implements Action 
{ 
    readonly type = DELETE_DRUG_FAILURE; 
    constructor ( public statusCode: number, public statusText: string )
    {
        // Null.
    }
}

/**
 * Get symptoms
 */
export const GET_SYMPTOMS = 'shared.symptoms.get';
export class GetSymptoms implements Action 
{ 
    readonly type = GET_SYMPTOMS;
    constructor ( )
    {
        // Null.
    } 
}

export const GET_SYMPTOMS_SUCCESS = 'shared.symptoms.get.success';
export class GetSymptomsSuccess implements Action 
{ 
    readonly type = GET_SYMPTOMS_SUCCESS; 
    constructor ( public symptoms: Symptom[] )
    {
        // Null.
    }
}

export const GET_SYMPTOMS_FAILURE = 'shared.symptoms.get.failure';
export class GetSymptomsFailure implements Action 
{ 
    readonly type = GET_SYMPTOMS_FAILURE; 
    constructor ( public statusCode: number, public statusText: string )
    {
        // Null.
    }
}

/**
 * Generate Patient Report
 */
export const GENERATE_PATIENT_REPORT = 'shared.patient.report.generate';
export class GeneratePatientReport implements Action 
{ 
    readonly type = GENERATE_PATIENT_REPORT;
    constructor ( public patient: string, public patientId: string, public patientSiteId, public report: string )
    {
        // Null.
    } 
}

export const GENERATE_PATIENT_REPORT_SUCCESS = 'shared.generate.patient.report.success';
export class GeneratePatientReportSuccess implements Action 
{ 
    readonly type = GENERATE_PATIENT_REPORT_SUCCESS; 
    constructor ( public patient: string, public patientId: string, public patientSiteId, public report: string, public raw: Blob )
    {
        // Null.
    }
}

export const GENERATE_PATIENT_REPORT_FAILURE = 'shared.generate.patient.report.failure';
export class GeneratePatientReportFailure implements Action 
{ 
    readonly type = GENERATE_PATIENT_REPORT_FAILURE; 
    constructor ( public statusCode: number, public statusText: string )
    {
        // Null.
    }
}

/**
 * Upload file
 */
export const UPLOAD_FILE = 'shared.file.upload';
export class UploadFile implements Action 
{ 
    readonly type = UPLOAD_FILE;
    constructor ( public site: string, public participant: string, public file: File )
    {
        // Null.
    } 
}

export const UPLOAD_FILE_SUCCESS = 'shared.file.upload.success';
export class UploadFileSuccess implements Action 
{ 
    readonly type = UPLOAD_FILE_SUCCESS; 
    constructor ( public id: string )
    {
        // Null.
    }
}

export const UPLOAD_FILE_FAILURE = 'shared.file.upload.failure';
export class UploadFileFailure implements Action 
{ 
    readonly type = UPLOAD_FILE_FAILURE; 
    constructor ( public statusCode: number, public statusText: string )
    {
        // Null.
    }
}

/**
 * Download File
 */
export const DOWNLOAD_FILE = 'shared.file.download';
export class DownloadFile implements Action 
{ 
    readonly type = DOWNLOAD_FILE;
    constructor ( public site: string, public participant: string, public file: string )
    {
        // Null.
    } 
}

export const DOWNLOAD_FILE_SUCCESS = 'shared.file.download.success';
export class DownloadFileSuccess implements Action 
{ 
    readonly type = DOWNLOAD_FILE_SUCCESS; 
    constructor ( public site: string, public participant: string, public file: string, public raw: Blob )
    {
        // Null.
    }
}

export const DOWNLOAD_FILE_FAILURE = 'shared.file.download.failure';
export class DownloadFileFailure implements Action 
{ 
    readonly type = DOWNLOAD_FILE_FAILURE; 
    constructor ( public statusCode: number, public statusText: string )
    {
        // Null.
    }
}