import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientDetailsComponent } from './patient-details/patient-details.component';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { sharedReducer } from './shared.reducers';
import { SharedEffects } from './shared.effects';
import { SharedService } from './shared.service';
import { UserResolver } from './user.resolver';
import { UsersResolver } from './users.resolver';
import { SitesResolver } from './sites.resolver';
import { CarerDetailsComponent } from './carer-details/carer-details.component';
import { DemographicsComponent } from './demographics/demographics.component';
import { DemographicsFUComponent } from './demographics-fu/demographics-fu.component';
import { ConsentComponent } from './consent/consent.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MocaComponent } from './moca/moca.component';
import { MocaComponent72 } from './moca72/moca72.component';
import { DrugHistoryComponent } from './drug-history/drug-history.component';
import { RandomisationComponent } from './randomisation/randomisation.component';
import { DrugDefinitionsResolver } from './drug-definitions.resolver';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SymptomsResolver } from './symptoms.resolver';
import { ChangeNotesComponent } from './change-notes/change-notes.component';
import { EligibilityComponent } from './eligibility/eligibility.component';
import { MedicalContactComponent } from './medical-contact/medical-contact.component';
import { PreAssessmentsComponent } from './pre-assessments/pre-assessments.component';
import { SecurePipe } from './SecurePipe';
import { RolesResolver } from './roles.resolver';
import { MedicalHistoryComponent } from './medical-history/medical-history.component';
import { DiagnosesHistoryComponent } from './diagnoses-history/diagnoses-history.component';
import { SurgeriesHistoryComponent } from './surgeries-history/surgeries-history.component';
import { ClinicalAssessmentComponent } from './clinical-assessment/clinical-assessment.component';
import { CarerConsentComponent } from './carer-consent/carer-consent.component';
import { CentralRoleResolver } from './central-role.resolver';
import { AppRoleResolver } from './app-role.resolver';
import { CarerDemographicsComponent } from './carer-demographics/carer-demographics.component';
import { SafeHtmlPipe } from './SafeHtmlPipe';
import { NhsNumberPipe } from './NhsNumberPipe';
import { LogoutNotificationComponent } from './logout-notification/logout-notification.component'
import { ConfirmModalTemplate } from './confirm-modal-template';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { ErrorComponent } from './error/error.component';
import { UnauthorizedErrorComponent } from './unauthorized-error/unauthorized-error.component';
import { HealthcareComponent } from './healthcare/healthcare.component';

@NgModule({
  declarations: [ 
    PatientDetailsComponent,
    CarerDetailsComponent,
    DemographicsComponent,
    DemographicsFUComponent,
    CarerDemographicsComponent,
    DrugHistoryComponent,
    ConsentComponent,
    MocaComponent,
    MocaComponent72,
    RandomisationComponent,
    ChangeNotesComponent,
    EligibilityComponent,
    HealthcareComponent,
    MedicalContactComponent,
    PreAssessmentsComponent,
    SecurePipe,
    MedicalHistoryComponent,
    DiagnosesHistoryComponent,
    SurgeriesHistoryComponent,
    ClinicalAssessmentComponent,
    CarerConsentComponent,
    SafeHtmlPipe,
    NhsNumberPipe,
    LogoutNotificationComponent,
    ConfirmModalTemplate,
    ErrorComponent,
    UnauthorizedErrorComponent
  ],
  entryComponents: [
    ChangeNotesComponent,
    ConfirmModalTemplate
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    FontAwesomeModule,
    StoreModule.forFeature ( 'shared', sharedReducer ),
    EffectsModule.forFeature ( [ SharedEffects ] ),
    SelectDropDownModule
  ],
  exports: [
    PatientDetailsComponent, CarerDetailsComponent, DemographicsComponent, DemographicsFUComponent, CarerDemographicsComponent, 
    ConsentComponent, MocaComponent, MocaComponent72, RandomisationComponent, DrugHistoryComponent,
    ChangeNotesComponent, EligibilityComponent, HealthcareComponent,
    MedicalContactComponent, PreAssessmentsComponent, SecurePipe, MedicalHistoryComponent,
    DiagnosesHistoryComponent, SurgeriesHistoryComponent, ClinicalAssessmentComponent, CarerConsentComponent, SafeHtmlPipe, NhsNumberPipe, ConfirmModalTemplate, SelectDropDownModule,
    ErrorComponent, UnauthorizedErrorComponent
  ],
  providers: [
    SharedService, UserResolver, UsersResolver, SitesResolver, DrugDefinitionsResolver, SymptomsResolver, RolesResolver, CentralRoleResolver, AppRoleResolver
  ]
})
export class SharedModule { }
