import { ImmutableClass } from './ImmutableClass';
import { List, Map } from 'immutable';
import { Group } from './Group';

export class CurrentUser extends ImmutableClass
{
    /**
     * Constructor 
     * @param i existing map of attributes
     */
    public constructor ( i: Map<string, any> = undefined )
    {
        super ( i );
    }

    public get Id ( ): string
    {
        return <string>this.i.get ( 'id' );
    }
    
    public setId ( id: string ): CurrentUser
    {
        return new CurrentUser ( this.i.set ( 'id', id ) );
    }

    public get FirstName ( ): string
    {
        return <string>this.i.get ( 'firstName' );
    }

    public setFirstName ( firstName: string ): CurrentUser
    {
        return new CurrentUser ( this.i.set ( 'firstName', firstName ) );
    }

    public get LastName ( ): string
    {
        return <string>this.i.get ( 'lastName' );
    }

    public setLastName ( lastName: string ): CurrentUser
    {
        return new CurrentUser ( this.i.set ( 'lastName', lastName ) );
    }

    public get Email ( ): string
    {
        return <string>this.i.get ( 'email' );
    }

    public setEmail ( email: string ): CurrentUser
    {
        return new CurrentUser ( this.i.set ( 'email', email ) );
    }
    
    public setRoles ( roles: List<string> ) : CurrentUser
    {
        return new CurrentUser ( this.i.set ( 'roles', roles ) );
    }

    public get Roles ( ): List<string>
    {
        return <List<string>>this.i.get ( 'roles' );
    }

    public get Groups ( ): List<Group>
    {
        return <List<Group>>this.i.get ( 'groups' );
    }

    public setGroups ( groups: List<Group> ): CurrentUser
    {
        return new CurrentUser ( this.i.set ( 'groups', groups ) );
    }
}