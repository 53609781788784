import { Action } from '@ngrx/store';
import { GET_SELF, GET_SELF_SUCCESS, GET_SELF_FAILURE, GetSelf, GetSelfSuccess, 
         GetSelfFailure, GET_USERS, GET_USERS_SUCCESS, GET_USERS_FAILURE, GetUsers, 
         GetUsersSuccess, GetUsersFailure, CREATE_SITE, CreateSite, CREATE_SITE_SUCCESS, 
         CreateSiteSuccess, CreateSiteFailure, CREATE_SITE_FAILURE, UPDATE_SITE, 
         UPDATE_SITE_SUCCESS, UPDATE_SITE_FAILURE, UpdateSite, UpdateSiteSuccess, 
         UpdateSiteFailure, DELETE_SITE, DELETE_SITE_SUCCESS, DELETE_SITE_FAILURE, 
         DeleteSiteFailure, DeleteSiteSuccess, DeleteSite, CREATE_USER, 
         CREATE_USER_SUCCESS, CREATE_USER_FAILURE, UPDATE_USER, UPDATE_USER_SUCCESS, 
         UPDATE_USER_FAILURE, DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_FAILURE, 
         CreateUser, CreateUserSuccess, CreateUserFailure, UpdateUser, 
         UpdateUserSuccess, UpdateUserFailure, DeleteUser, DeleteUserSuccess, DeleteUserFailure, 
         GET_CENTRAL_SITES, GET_CENTRAL_SITES_SUCCESS, GET_CENTRAL_SITES_FAILURE, GetCentralSites, 
         GetCentralSitesSuccess, GetCentralSitesFailure, SEND_PASSWORD_RESET_EMAIL, 
         SEND_PASSWORD_RESET_EMAIL_SUCCESS, SEND_PASSWORD_RESET_EMAIL_FAILURE, SendPasswordResetEmail, 
         SendPasswordResetEmailSuccess, SendPasswordResetEmailFailure, GENERATE_UUIDS, 
         GENERATE_UUIDS_SUCCESS, GenerateUUIDs, GenerateUUIDsSuccess, GenerateUUIDsFailure, 
         CONSUME_UUID, ConsumeUUID, GENERATE_UUIDS_FAILURE, GET_DRUGS, GET_DRUGS_SUCCESS, 
         GET_DRUGS_FAILURE, CREATE_DRUG, CREATE_DRUG_SUCCESS, CREATE_DRUG_FAILURE, UPDATE_DRUG, 
         UPDATE_DRUG_SUCCESS, UPDATE_DRUG_FAILURE, DELETE_DRUG, DELETE_DRUG_FAILURE, DELETE_DRUG_SUCCESS, 
         GetDrugs, GetDrugsSuccess, GetDrugsFailure, CreateDrug, CreateDrugSuccess, CreateDrugFailure, 
         UpdateDrug, UpdateDrugSuccess, UpdateDrugFailure, DeleteDrug, DeleteDrugSuccess, DeleteDrugFailure, 
         GENERATE_PATIENT_REPORT, GENERATE_PATIENT_REPORT_SUCCESS, GENERATE_PATIENT_REPORT_FAILURE, 
         GeneratePatientReportSuccess, GeneratePatientReport, GeneratePatientReportFailure, GET_SYMPTOMS, 
         GET_SYMPTOMS_SUCCESS, GET_SYMPTOMS_FAILURE, GetSymptoms, GetSymptomsSuccess, GetSymptomsFailure, GET_ROLES, 
         GET_ROLES_SUCCESS, GET_ROLES_FAILURE, GetRoles, GetRolesSuccess, GetRolesFailure, 
         GET_DIAGNOSES, GET_DIAGNOSES_SUCCESS, GET_DIAGNOSES_FAILURE, GetDiagnoses, GetDiagnosesSuccess, 
         GetDiagnosesFailure, UPLOAD_FILE, UploadFile, UPLOAD_FILE_SUCCESS, UploadFileSuccess, UPLOAD_FILE_FAILURE, 
         DownloadFile, DOWNLOAD_FILE, DOWNLOAD_FILE_SUCCESS, DOWNLOAD_FILE_FAILURE, DownloadFileFailure, DownloadFileSuccess, 
         UploadFileFailure } from './shared.actions';
import { SharedState, DEFAULT_SHARED_STATE } from './shared.state';
import { List } from 'immutable';
import { User } from '../utils/User';
import { compareDrugDefinition } from '../utils/DrugDefinition';

/**
 * Shared State Reducer
 * 
 * @param state 
 * @param action 
 */
export function sharedReducer ( state: SharedState = DEFAULT_SHARED_STATE, action: Action ) : SharedState
{
    switch ( action.type )
    {
    case GET_CENTRAL_SITES:
        return getCentralSites ( state, action as GetCentralSites );
    case GET_CENTRAL_SITES_SUCCESS:
        return getCentralSitesSuccess ( state, action as GetCentralSitesSuccess );
    case GET_CENTRAL_SITES_FAILURE:
        return getCentralSitesFailure ( state, action as GetCentralSitesFailure );
    case GENERATE_UUIDS:
        return generateUUIDs ( state, action as GenerateUUIDs );
    case GENERATE_UUIDS_SUCCESS:
        return generateUUIDsSuccess ( state, action as GenerateUUIDsSuccess );
    case GENERATE_UUIDS_FAILURE:
        return generateUUIDsFailure ( state, action as GenerateUUIDsFailure );
    case CONSUME_UUID:
        return consumeUUID ( state, action as ConsumeUUID );
    case CREATE_SITE:
        return createSite ( state, action as CreateSite );
    case CREATE_SITE_SUCCESS:
        return createSiteSuccess ( state, action as CreateSiteSuccess );
    case CREATE_SITE_FAILURE:
        return createSiteFailure ( state, action as CreateSiteFailure );
    case UPDATE_SITE:
        return updateSite ( state, action as UpdateSite );
    case UPDATE_SITE_SUCCESS:
        return updateSiteSuccess ( state, action as UpdateSiteSuccess );
    case UPDATE_SITE_FAILURE:
        return updateSiteFailure ( state, action as UpdateSiteFailure );
    case DELETE_SITE:
        return deleteSite ( state, action as DeleteSite );
    case DELETE_SITE_SUCCESS:
        return deleteSiteSuccess ( state, action as DeleteSiteSuccess );
    case DELETE_SITE_FAILURE:
        return deleteSiteFailure ( state, action as DeleteSiteFailure );
    case GET_ROLES:
        return getRoles ( state, action as GetRoles );
    case GET_ROLES_SUCCESS:
        return getRolesSuccess ( state, action as GetRolesSuccess );
    case GET_ROLES_FAILURE:
        return getRolesFailure ( state, action as GetRolesFailure );
    case CREATE_USER:
        return createUser ( state, action as CreateUser );
    case CREATE_USER_SUCCESS:
        return createUserSuccess ( state, action as CreateUserSuccess );
    case CREATE_USER_FAILURE:
        return createUserFailure ( state, action as CreateUserFailure );
    case UPDATE_USER:
        return updateUser ( state, action as UpdateUser );
    case UPDATE_USER_SUCCESS:
        return updateUserSuccess ( state, action as UpdateUserSuccess );
    case UPDATE_USER_FAILURE:
        return updateUserFailure ( state, action as UpdateUserFailure );
    case DELETE_USER:
        return deleteUser ( state, action as DeleteUser );
    case DELETE_USER_SUCCESS:
        return deleteUserSuccess ( state, action as DeleteUserSuccess );
    case DELETE_USER_FAILURE:
        return deleteUserFailure ( state, action as DeleteUserFailure );
    case GET_USERS:
        return getUsers ( state, action as GetUsers );
    case GET_USERS_SUCCESS:
        return getUsersSuccess ( state, action as GetUsersSuccess );
    case GET_USERS_FAILURE:
        return getUsersFailure ( state, action as GetUsersFailure );
    case GET_SELF:
        return getSelf ( state, action as GetSelf );
    case GET_SELF_SUCCESS:
        return getSelfSuccess ( state, action as GetSelfSuccess );
    case GET_SELF_FAILURE:
        return getSelfFailure ( state, action as GetSelfFailure );
    case SEND_PASSWORD_RESET_EMAIL:
        return sendPasswordReset ( state, action as SendPasswordResetEmail );
    case SEND_PASSWORD_RESET_EMAIL_SUCCESS:
        return sendPasswordResetSuccess ( state, action as SendPasswordResetEmailSuccess );
    case SEND_PASSWORD_RESET_EMAIL_FAILURE:
        return sendPasswordResetFailure ( state, action as SendPasswordResetEmailFailure );
    case GET_DIAGNOSES:
        return getDiagnoses ( state, action as GetDiagnoses );
    case GET_DIAGNOSES_SUCCESS:
        return getDiagnosesSuccess ( state, action as GetDiagnosesSuccess );
    case GET_DIAGNOSES_FAILURE:
        return getDiagnosesFailure ( state, action as GetDiagnosesFailure );
    case GET_DRUGS:
        return getDrugs ( state, action as GetDrugs );
    case GET_DRUGS_SUCCESS:
        return getDrugsSuccess ( state, action as GetDrugsSuccess );
    case GET_DRUGS_FAILURE:
        return getDrugsFailure ( state, action as GetDrugsFailure );
    case CREATE_DRUG:
        return createDrug ( state, action as CreateDrug );
    case CREATE_DRUG_SUCCESS:
        return createDrugSuccess ( state, action as CreateDrugSuccess );
    case CREATE_DRUG_FAILURE:
        return createDrugFailure ( state, action as CreateDrugFailure );
    case UPDATE_DRUG:
        return updateDrug ( state, action as UpdateDrug );
    case UPDATE_DRUG_SUCCESS:
        return updateDrugSuccess ( state, action as UpdateDrugSuccess );
    case UPDATE_DRUG_FAILURE:
        return updateDrugFailure ( state, action as UpdateDrugFailure );
    case DELETE_DRUG:
        return deleteDrug ( state, action as DeleteDrug );
    case DELETE_DRUG_SUCCESS:
        return deleteDrugSuccess ( state, action as DeleteDrugSuccess );
    case DELETE_DRUG_FAILURE:
        return deleteDrugFailure ( state, action as DeleteDrugFailure );
    case GET_SYMPTOMS:
        return getSymptoms ( state, action as GetSymptoms );
    case GET_SYMPTOMS_SUCCESS:
        return getSymptomsSuccess ( state, action as GetSymptomsSuccess );
    case GET_SYMPTOMS_FAILURE:
        return getSymptomsFailure ( state, action as GetSymptomsFailure );
    case GENERATE_PATIENT_REPORT:
        return generatePatientReport ( state, action as GeneratePatientReport );
    case GENERATE_PATIENT_REPORT_SUCCESS:
        return generatePatientReportSuccess ( state, action as GeneratePatientReportSuccess );
    case GENERATE_PATIENT_REPORT_FAILURE:
        return generatePatientReportFailure ( state, action as GeneratePatientReportFailure );
    case UPLOAD_FILE:
        return uploadFile ( state, action as UploadFile );
    case UPLOAD_FILE_SUCCESS:
        return uploadFileSuccess ( state, action as UploadFileSuccess );
    case UPLOAD_FILE_FAILURE:
        return uploadFileFailure ( state, action as UploadFileFailure );
    case DOWNLOAD_FILE:
        return downloadFile ( state, action as DownloadFile );
    case DOWNLOAD_FILE_SUCCESS:
        return downloadFileSuccess ( state, action as DownloadFileSuccess );
    case DOWNLOAD_FILE_FAILURE:
        return downloadFileFailure ( state, action as DownloadFileFailure );
    default:
        return state;
    }
}

function generateUUIDs ( state: SharedState, action: GenerateUUIDs ): SharedState
{
    return state;
}

function generateUUIDsSuccess ( state: SharedState, action: GenerateUUIDsSuccess ): SharedState
{ 
    return state.setUUIDs ( action.uuids );
}

function generateUUIDsFailure ( state: SharedState, action: GenerateUUIDsFailure ): SharedState
{ 
    console.log ( `Code: ${action.statusCode} - Message: ${action.statusText}` );
    return state;
}

function consumeUUID ( state: SharedState, action: ConsumeUUID ): SharedState
{ 
    let uuids = [...state.UUIDs];
    uuids.splice ( 0, 1 );
    return state.setUUIDs ( uuids );
}

function getCentralSites ( state: SharedState, action: GetCentralSites ): SharedState
{
    return state;
}

function getCentralSitesSuccess ( state: SharedState, action: GetCentralSitesSuccess ): SharedState
{ 
    return state.setSites ( List ( action.sites ) ).setRootGroupId ( action.rootGroupId );
}

function getCentralSitesFailure ( state: SharedState, action: GetCentralSitesFailure ): SharedState
{ 
    throw({ statusCode: action.statusCode, statusText: action.statusText, type: action.type});
    // return state;//.setCentral ( null );
}

function createSite ( state: SharedState, action: CreateSite ): SharedState
{
    return state;
}

function createSiteSuccess ( state: SharedState, action: CreateSiteSuccess ): SharedState
{ 
    let sites = state.Sites.toArray ( );
    sites.push ( action.site );
    return state.setSites ( List ( sites ) );
}

function createSiteFailure ( state: SharedState, action: CreateSiteFailure ): SharedState
{ 
    throw({ statusCode: action.statusCode, statusText: action.statusText, type: action.type});
    // return state;
}

function updateSite ( state: SharedState, action: UpdateSite ): SharedState
{
    return state;
}

function updateSiteSuccess ( state: SharedState, action: UpdateSiteSuccess ): SharedState
{ 
    let sites = state.Sites.toArray ( );
    for ( let i = 0; i < sites.length; i++ )
    {
        let site = sites [ i ];
        if ( site.Id == action.site.Id )
        {
            sites [ i ] = action.site;
            break;
        }
    }
 
    return state.setSites ( List ( sites ) );
}

function updateSiteFailure ( state: SharedState, action: UpdateSiteFailure ): SharedState
{ 
    throw({ statusCode: action.statusCode, statusText: action.statusText, type: action.type});
    // return state;
}

function deleteSite ( state: SharedState, action: DeleteSite ): SharedState
{
    return state;
}

function deleteSiteSuccess ( state: SharedState, action: DeleteSiteSuccess ): SharedState
{ 
    let sites = [];
    sites.push ( ...state.Sites.toArray ( ) );
    for ( let i = 0; i < sites.length; i++ )
    {
        let site = sites [ i ];
        if ( site.Group == action.id )
        {
            sites.splice ( i, 1 );
            break;
        }
    }

    return state.setSites ( List ( sites ) );
}

function deleteSiteFailure ( state: SharedState, action: DeleteSiteFailure ): SharedState
{ 
    throw({ statusCode: action.statusCode, statusText: action.statusText, type: action.type});
    // return state;
}

function getRoles ( state: SharedState, action: GetRoles ): SharedState
{
    return state;
}

function getRolesSuccess ( state: SharedState, action: GetRolesSuccess ): SharedState
{ 
    return state.setRoles ( List ( action.roles ) ).
                 setAppRole ( action.app ).
                 setSystemAdminRole ( action.system ).
                 setCentralAdminRole ( action.central ).
                 setCentralInvestigatorRole ( action.medic_ci ).
                 setPrincipalInvestigatorRole ( action.medic_pi ).
                 setPharmacyRole ( action.pharmacy );
}

function getRolesFailure ( state: SharedState, action: GetRolesFailure ): SharedState
{ 
    throw({ statusCode: action.statusCode, statusText: action.statusText, type: action.type});
    // return state;
}

function createUser ( state: SharedState, action: CreateUser ): SharedState
{
    return state;
}

function createUserSuccess ( state: SharedState, action: CreateUserSuccess ): SharedState
{ 
    let users = state.Users.toArray ( );
    users.push ( action.user );
    return state.setUsers ( List ( users ) );
}

function createUserFailure ( state: SharedState, action: CreateUserFailure ): SharedState
{ 
    throw({ statusCode: action.statusCode, statusText: action.statusText, type: action.type});
    // return state;
}

function updateUser ( state: SharedState, action: UpdateUser ): SharedState
{
    return state;
}

function updateUserSuccess ( state: SharedState, action: UpdateUserSuccess ): SharedState
{ 
    let users = state.Users.toArray ( );
    for ( let i = 0; i < users.length; i++ )
    {
        let user = users [ i ];
        if ( user.Id == action.user.Id )
        {
            users [ i ] = action.user;
            break;
        }
    }
 
    return state.setUsers ( List ( users ) );
}

function updateUserFailure ( state: SharedState, action: UpdateUserFailure ): SharedState
{ 
    throw({ statusCode: action.statusCode, statusText: action.statusText, type: action.type});
    // return state;
}

function deleteUser ( state: SharedState, action: DeleteUser ): SharedState
{
    return state;
}

function deleteUserSuccess ( state: SharedState, action: DeleteUserSuccess ): SharedState
{ 
    let users: User[] = [];
    users.push ( ...state.Users.toArray ( ) );
    for ( let i = 0; i < users.length; i++ )
    {
        let user = users [ i ];
        if ( user.Id == action.id )
        {
            users.splice ( i, 1 );
            break;
        }
    }

    return state.setUsers ( List ( users ) );
}

function deleteUserFailure ( state: SharedState, action: DeleteUserFailure ): SharedState
{ 
    throw({ statusCode: action.statusCode, statusText: action.statusText, type: action.type});
    // return state;
}

function getUsers ( state: SharedState, action: GetUsers ): SharedState
{
    return state.setUsers ( null );
}

function getUsersSuccess ( state: SharedState, action: GetUsersSuccess ): SharedState
{ 
    return state.setUsers ( List ( action.users ) );
}

function getUsersFailure ( state: SharedState, action: GetUsersFailure ): SharedState
{ 
    throw({ statusCode: action.statusCode, statusText: action.statusText, type: action.type});
    // return state.setSites ( null );
}

function getSelf ( state: SharedState, action: GetSelf ): SharedState
{
    return state;
}

function getSelfSuccess ( state: SharedState, action: GetSelfSuccess ): SharedState
{ 
    return state.setSelf ( action.self );
}

function getSelfFailure ( state: SharedState, action: GetSelfFailure ): SharedState
{ 
    throw({ statusCode: action.statusCode, statusText: action.statusText, type: action.type});
    // return state.setSelf ( null );
}

function sendPasswordReset ( state: SharedState, action: SendPasswordResetEmail ): SharedState
{
    return state.setSelf ( null );
}

function sendPasswordResetSuccess ( state: SharedState, action: SendPasswordResetEmailSuccess ): SharedState
{ 
    return state;
}

function sendPasswordResetFailure ( state: SharedState, action: SendPasswordResetEmailFailure ): SharedState
{ 
    throw({ statusCode: action.statusCode, statusText: action.statusText, type: action.type});
    // return state;
}

function getDiagnoses ( state: SharedState, action: GetDiagnoses ): SharedState
{
    return state.setDiagnoses ( null );
}

function getDiagnosesSuccess ( state: SharedState, action: GetDiagnosesSuccess ): SharedState
{ 
    return state.setDiagnoses ( List ( action.diagnoses ) );
}

function getDiagnosesFailure ( state: SharedState, action: GetDiagnosesFailure ): SharedState
{ 
    throw({ statusCode: action.statusCode, statusText: action.statusText, type: action.type});
    // return state.setDiagnoses ( null );
}

function getDrugs ( state: SharedState, action: GetDrugs ): SharedState
{
    return state;
}

function getDrugsSuccess ( state: SharedState, action: GetDrugsSuccess ): SharedState
{ 
    return state.setDrugs ( List ( action.drugs ) );
}

function getDrugsFailure ( state: SharedState, action: GetDrugsFailure ): SharedState
{ 
    throw({ statusCode: action.statusCode, statusText: action.statusText, type: action.type});
    // return state.setDrugs ( null );
}

function createDrug ( state: SharedState, action: CreateDrug ): SharedState
{
    return state;
}

function createDrugSuccess ( state: SharedState, action: CreateDrugSuccess ): SharedState
{ 
    let drugs = state.Drugs.toArray ( );
    drugs.push ( action.drug );
    drugs.sort ( compareDrugDefinition );
    return state.setDrugs ( List ( drugs ) );
}

function createDrugFailure ( state: SharedState, action: CreateDrugFailure ): SharedState
{ 
    throw({ statusCode: action.statusCode, statusText: action.statusText, type: action.type});
    // return state;
}

function updateDrug ( state: SharedState, action: UpdateDrug ): SharedState
{
    return state;
}

function updateDrugSuccess ( state: SharedState, action: UpdateDrugSuccess ): SharedState
{ 
    let drugs = state.Drugs.toArray ( );
    for ( let i = 0; i < drugs.length; i++ )
    {
        let drug = drugs [ i ];
        if ( drug.Id == action.drug.Id )
        {
            drugs [ i ] = action.drug;
            break;
        }
    }
    drugs.sort ( compareDrugDefinition );
    return state.setDrugs ( List ( drugs ) );
}

function updateDrugFailure ( state: SharedState, action: UpdateDrugFailure ): SharedState
{ 
    throw({ statusCode: action.statusCode, statusText: action.statusText, type: action.type});
    // return state;
}

function deleteDrug ( state: SharedState, action: DeleteDrug ): SharedState
{
    return state;
}

function deleteDrugSuccess ( state: SharedState, action: DeleteDrugSuccess ): SharedState
{ 
    let drugs = [];
    drugs.push ( ...state.Drugs.toArray ( ) );
    for ( let i = 0; i < drugs.length; i++ )
    {
        let drug = drugs [ i ];
        if ( drug.Id == action.id )
        {
            drugs.splice ( i, 1 );
            break;
        }
    }

    return state.setDrugs ( List ( drugs ) );
}

function deleteDrugFailure ( state: SharedState, action: DeleteDrugFailure ): SharedState
{ 
    throw({ statusCode: action.statusCode, statusText: action.statusText, type: action.type});
    // return state;
}

function getSymptoms ( state: SharedState, action: GetSymptoms ): SharedState
{
    return state;
}

function getSymptomsSuccess ( state: SharedState, action: GetSymptomsSuccess ): SharedState
{ 
    return state.setSymptoms ( List ( action.symptoms ) );
}

function getSymptomsFailure ( state: SharedState, action: GetSymptomsFailure ): SharedState
{ 
    throw({ statusCode: action.statusCode, statusText: action.statusText, type: action.type});
    // return state.setSymptoms ( null );
}

function generatePatientReport ( state: SharedState, action: GeneratePatientReport ): SharedState
{
    return state;
}

function generatePatientReportSuccess ( state: SharedState, action: GeneratePatientReportSuccess ): SharedState
{
    return state.setPatientReport ( [action.report, action.raw] );
}

function generatePatientReportFailure ( state: SharedState, action: GeneratePatientReportFailure ): SharedState
{ 
    throw({ statusCode: action.statusCode, statusText: action.statusText, type: action.type});
    // return state.setPatientReport ( null );
}

function uploadFile ( state: SharedState, action: UploadFile ): SharedState
{
    return state.setLastUploadId ( null );
}

function uploadFileSuccess ( state: SharedState, action: UploadFileSuccess ): SharedState
{ 
    return state.setLastUploadId ( action.id );
}

function uploadFileFailure ( state: SharedState, action: UploadFileFailure ): SharedState
{ 
    throw({ statusCode: action.statusCode, statusText: action.statusText, type: action.type});
    // return state.setLastUploadId ( null );
}

function downloadFile ( state: SharedState, action: DownloadFile ): SharedState
{
    return state;
}

function downloadFileSuccess ( state: SharedState, action: DownloadFileSuccess ): SharedState
{ 
    return state.setDownload ( [ action.site, action.participant, action.file, action.raw ] );
}

function downloadFileFailure ( state: SharedState, action: DownloadFileFailure ): SharedState
{ 
    throw({ statusCode: action.statusCode, statusText: action.statusText, type: action.type});
    // return state.setDownload ( null );
}