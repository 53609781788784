import { ImmutableClass } from './ImmutableClass';
import { Map } from 'immutable';

export const ROLE_PARTICIPANT_DETAILS_VIEW = 'participant_details_view';
export const ROLE_PARTICIPANT_DETAILS_EDIT = 'participant_details_edit';
export const ROLE_STUDY_DETAILS_VIEW = 'study_details_view';
export const ROLE_STUDY_DETAILS_EDIT = 'study_details_edit';
export const ROLE_AE_RECORD = 'ae_record';
export const ROLE_AE_REQUEST_SIGNATURE = 'ae_request_sig';
export const ROLE_AE_SIGN = 'ae_sign';
export const ROLE_COMPLIANCE_VIEW = 'compliance_view';
export const ROLE_REPORTS_VIEW = 'reports_view';
export const ROLE_FALLS_DIARY_VIEW = 'falls_diary_view';
export const ROLE_FALLS_DIARY_EDIT = 'falls_diary_edit';
export const ROLE_USERS_VIEW = 'users_view';
export const ROLE_USERS_EDIT = 'users_edit';
export const ROLE_SITES_VIEW = 'sites_view';
export const ROLE_SITES_EDIT = 'sites_edit';
export const ROLE_SYSTEMS_ADMIN = 'system_admin';
export const ROLE_CENTRAL_ADMIN = 'central_admin';
export const ROLE_BLINDED_MEDIC_CI = 'blinded_medic_ci';
export const ROLE_BLINDED_MEDIC_PI = 'blinded_medic_pi';
export const ROLE_BLINDED_RATER = 'blinded_rater';
export const ROLE_UNBLINDED_PHARMACY = 'unblinded_pharmacy';
export const ROLE_CHANGE_LOG_VIEW = 'change_log_view'


export class Role extends ImmutableClass
{
    /**
     * Constructor 
     * @param i existing map of attributes
     */
    public constructor ( i: Map<string, any> = undefined )
    {
        super ( i );
    }

    public get Id ( ): string
    {
        return <string>this.i.get ( 'id' );
    }

    public setId ( id: string ): Role
    {
        return new Role ( this.i.set ( 'id', id ) );
    }

    public get Name ( ): string
    {
        return <string>this.i.get ( 'name' );
    }

    public setName ( name: string ): Role
    {
        return new Role ( this.i.set ( 'name', name ) );
    }

    public get Description ( ): string
    {
        return <string>this.i.get ( 'description' );
    }

    public setDescription ( description: string ): Role
    {
        return new Role ( this.i.set ( 'description', description ) );
    }

    public get IsTopLevelRole ( ) : boolean
    {
        return <boolean>this.i.get ( 'isTopLevelRole' );
    }

    public setIsTopLevelRole ( isTopLevelRole: boolean )
    {
        return new Role ( this.i.set ( 'isTopLevelRole', isTopLevelRole ) );
    }

    public get Title ( ): string
    {
        return <string>this.i.get ( 'title' );
    }

    public setTitle ( title: string ): Role
    {
        return new Role ( this.i.set ( 'title', title ) );
    }

    
}