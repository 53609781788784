import { KeycloakConfig } from 'keycloak-angular';

// Add here your keycloak setup infos
let keycloakConfig: KeycloakConfig = {
  url: 'https://auth.chief-pd.co.uk/auth',
  realm: 'chiefpd',
  clientId: 'web-app'
};

export const environment = {
  name: 'prod',
  production: true,
  bannerText: null,
  keycloak: keycloakConfig,
  api: 'https://api.chief-pd.co.uk/api',
  sentry_url: 'https://04ba15b847f34ff19ca3dbd2d78b7e2a@sentry.io/1511672',
  build: 'dbc108e9'
};
