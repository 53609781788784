import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SharedService } from './shared.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { DrugDefinition } from '../utils/DrugDefinition';


@Injectable()
export class DrugDefinitionsResolver implements Resolve<DrugDefinition[]>
{
    constructor ( private service: SharedService )
    {
        // Null.
    }

    resolve ( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<DrugDefinition[]> 
    {
        return this.service.getDrugs ( ).pipe ( take ( 1 ) );
    }
}