import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SharedService } from './shared.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Role } from '../utils/Role';


@Injectable()
export class CentralRoleResolver implements Resolve<Role>
{
    constructor ( private service: SharedService )
    {
        // Null.
    }

    resolve ( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<Role> 
    {
        return this.service.getCentralAdminRole ( ).pipe ( take ( 1 ) );
    }
}