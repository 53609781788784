import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { User } from './utils/User';
import { SharedService } from './shared/shared.service';
import { Subscription, from } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { faExclamationTriangle, faClock, faHistory } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LogoutNotificationComponent } from './shared/logout-notification/logout-notification.component';
import { map, tap, mergeMap } from 'rxjs/operators';
import { CurrentUser } from './utils/CurrentUser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy
{
  title = 'mle';

  sub: Subscription;

  self: CurrentUser;

  bannerText = environment.bannerText;

  idleState = 'Not started.';
  icon = faClock;
  timedOut = false;
  // lastPing?: Date = null;
  showIdle = false;
  finalWarning = false;

  readonly faExclamationTriangle = faExclamationTriangle;
  readonly faClock = faClock;
  readonly faHistory = faHistory;


  constructor ( private service: SharedService, private keycloak: KeycloakService,
                private idle: Idle, private keepalive: Keepalive, private cdr: ChangeDetectorRef,
                private modalService: NgbModal )
  {
    // Set idle time in seconds until countdown starts 
    idle.setIdle ( 50 * 60 );
    // sets countdown time in seconds. Total idle time until log out is sum of timeout and idle times.
    idle.setTimeout ( 10 * 60 );
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onTimeoutWarning.subscribe((countdown) => {
      // time remaining to be colour red.
      let dangerPeriod = 1 * 60;

      this.icon = countdown < dangerPeriod ? this.faExclamationTriangle : this.faClock;
      this.finalWarning = countdown < dangerPeriod;
      this.idleState = `Auto-logout in ${this.secondsToTime ( countdown )}`;
      this.showIdle = true;
      cdr.detectChanges();
    });

    idle.onIdleEnd.subscribe(() => {
      this.icon = this.faHistory;
      this.finalWarning = false;
      this.idleState = 'No longer idle.';
      cdr.detectChanges();
      
      // wait 5 seconds before hiding the idle box.
      setTimeout(() => {
        this.showIdle = false;
        cdr.detectChanges(); 
      }, 5000);
    });

    // End of total idle time reached. Log out!
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      cdr.detectChanges();
      this.timedOut = true;

      // A nice modal like this one would be better, but the modalservice dies after logout.
      // this.modalService.open ( LogoutNotificationComponent, { centered: true } );
      
      setTimeout(() => {
        this.keycloak.logout();
        alert ( 'Automated Logout\n\n' + 
                'You have been automatically logged out.\n' + 
                'This is because the application was idle for more than 1 hour. Please log in again.' ) ;
      }, 100);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);
    // keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.startIdleTimer();
  }

  ngOnInit ( )
  {
    this.sub = from ( this.keycloak.getToken ( ) ).pipe (
      map ( token => JSON.parse ( atob ( token.split ( '.' ) [ 1 ] ) ) ),
      mergeMap ( token => this.service.getSelf ( token ) ) ).subscribe ( user => this.self = user ); 
  }

  ngOnDestroy ( )
  {
    if ( this.sub )
    {
      this.sub.unsubscribe ( );
      this.sub = null;
    }
  }

  logout ( )
  {
    this.keycloak.logout ( );
  }

  startIdleTimer() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  secondsToTime ( seconds: number) 
  {
    const minutes: number = Math.floor(seconds / 60);
    return ( "0" + minutes ).slice ( -2 ) + 'm' + ( "0" + ( seconds - minutes * 60)).slice( -2 ) + 's';
  }

}
