<div class="container-fluid">
  <div class="row ">
    <div class="col-12 vh-100 d-flex flex-column justify-content-center align-items-center">
      <h1 class="pt-3 display-1 text-dark-green text-center">Sorry,</h1>
      <h2 class="pt-3 text-dark-green text-center">your account is not authorized to perform this task.</h2>
      <div class="col pt-5 d-flex justify-content-center flex-grow-0">
        <button class="btn btn-dark-green btn-lg" (click)="goHome()"><fa-icon [icon]="faHome" [fixedWidth]="true" class="pr-2"></fa-icon>Back to dashboard</button>
      </div>
    </div>
  </div>
</div>