<nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
  <a class="navbar-brand col-sm-3 col-md-2 mr-0" href="#"><img src="assets/logo-small.ico" class="pr-2">CHIEF-PD</a>
  <ul class="navbar-nav mr-auto pl-5">
    <li *ngIf="bannerText != null" class="nav-item">
      <a class="nav-link text-danger bg-white px-2 disabled"><div [innerHTML]="bannerText"></div></a>
    </li>
  </ul>
  <ul class="navbar-nav px-3 navbar-expand-lg">
    <li class="nav-item" [ngClass]="{ 'show-idle' : showIdle, 'hide-idle' : !showIdle }">
      <div class="nav-link mr-3 disabled bg-white px-3 rounded shadow" [ngClass]="{ 'text-danger' : finalWarning, 'text-dark-green' : !finalWarning }">
        <fa-icon [icon]="icon" [fixedWidth]="true" class="mr-1"></fa-icon>
        <strong>{{idleState}}</strong>
      </div>
    </li>
    <li class="nav-item">
      <a class="nav-link" (click)="logout()">Sign out - {{self?.FirstName}} {{self?.LastName}}</a>
    </li>
  </ul>
</nav>

<router-outlet></router-outlet>
<ngx-spinner></ngx-spinner>
