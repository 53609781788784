import { ImmutableClass } from './ImmutableClass';
import { List } from 'immutable';

export function compareDrugDefinition ( a: DrugDefinition, b: DrugDefinition )
{
    if ( a.Name < b.Name )
    {
      return -1;
    }
    else if ( a.Name > b.Name )
    {
      return 1;
    }
    else
    {
        return 0;
    }
  }

export class DrugDefinition extends ImmutableClass
{
    public static fromJSON ( json: any ) : DrugDefinition[]
    {
        let drugs: DrugDefinition [ ] = [ ];
        for ( let drug of json )
        {
            drugs.push ( new DrugDefinition ( { id: drug.drug_id, name: drug.Drug_name, dose: drug.Drug_dose, chemicalName: drug.Chemical_name } ) );
        }
        return drugs
    }

    public static toJSON ( drugs: List<DrugDefinition> ) : any
    {
        let res = [];
        if ( drugs != null )
        {
            for ( let drug of drugs.toArray ( ) )
            {
                res.push ( { drug_id: drug.Id, Drug_name: drug.Name, Drug_dose: drug.Dose, Chemical_name: drug.ChemicalName } );
            }
        }
        return res;
    }

    /**
     * Constructor 
     * @param i existing map of attributes
     */
    public constructor ( i: any = undefined )
    {
        super ( i || { id: null, name: null, dose: null, chemicalName: null } );
    }

    public get Id ( ): string
    {
        return <string>this.i.get ( 'id' );
    }

    public setId ( id: string ): DrugDefinition
    {
        return new DrugDefinition ( this.i.set ( 'id', id ) );
    }

    public get Name ( ): string
    {
        return <string>this.i.get ( 'name' );
    }

    public setName ( name: string ): DrugDefinition
    {
        return new DrugDefinition ( this.i.set ( 'name', name ) );
    }

    public get Dose ( ): string
    {
        return <string>this.i.get ( 'dose' );
    }

    public setDose ( dose: string ): DrugDefinition
    {
        return new DrugDefinition ( this.i.set ( 'dose', dose ) );
    }

    public get ChemicalName ( ): string
    {
        return <string>this.i.get ( 'chemicalName' );
    }

    public setChemicalName ( chemicalName: string ): DrugDefinition
    {
        return new DrugDefinition ( this.i.set ( 'chemicalName', chemicalName ) );
    }
}