import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SharedService } from './shared.service';
import { Observable, from } from 'rxjs';
import { take, map, mergeMap } from 'rxjs/operators';
import { CurrentUser } from '../utils/CurrentUser';
import { KeycloakService } from 'keycloak-angular';


@Injectable()
export class UserResolver implements Resolve<CurrentUser>
{
    constructor ( private service: SharedService, private keycloak: KeycloakService )
    {
        // Null.
    }

    resolve ( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<CurrentUser> 
    {
        return from ( this.keycloak.getToken ( ) ).pipe (
            map ( token => JSON.parse ( atob ( token.split ( '.' ) [ 1 ] ) ) ),
            mergeMap ( token => this.service.getSelf ( token ) ),
            take ( 1 ) );
    }
}