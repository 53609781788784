import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable, DoBootstrap, NgZone, Injector } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import * as Sentry from '@sentry/browser';
import { ErrorHandler } from '@angular/core';
import { NgxSpinnerModule } from "ngx-spinner";
import { SpinnerInterceptor } from './spinner.interceptor';
import { BusyService } from './busy.service';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { LogoutNotificationComponent } from './shared/logout-notification/logout-notification.component';
import { Router } from '@angular/router';

const keycloakService = new KeycloakService ( );

// Setup sentry for sending all the goodness
Sentry.init ( {
  dsn: environment.sentry_url,
  enabled: environment.production,
  environment: environment.name,
  release: environment.build
} );

@Injectable()
export class SentryErrorHandler implements ErrorHandler 
{
  constructor ( )
  {
    // Null.
  }

  handleError ( error )
  {
    if ( error )
    {
      const eventId = Sentry.captureException ( error.originalError || error );
      if ( error instanceof HttpErrorResponse )
      {
        console.log(error.status);
      }
      else
      {
        console.error("an error occured here");
        Sentry.showReportDialog({ eventId });
      }
    }
    else
    {
      Sentry.captureMessage ( 'Caught null error!' );
    }
  }
}

@Injectable()
export class AppErrorHandler implements ErrorHandler
{
    private sentryErrorHandler: SentryErrorHandler = null;    

    constructor ( private injector: Injector, private zone: NgZone )
    {
        // Only create the sentry error handler for production builds
        if ( environment.production )
        {
            this.sentryErrorHandler = new SentryErrorHandler ( );
        }
    }

    handleError ( error )
    {
        // Log it locally
        console.error ( error );

        // Pass it to sentry, if there is need to
        if ( this.sentryErrorHandler )
        {
            this.sentryErrorHandler.handleError ( error );
        }
        
        // Redirect to error page, this might not be ideal in a dev build, lets see?
        this.zone.run ( ( ) => {
            const router = this.injector.get ( Router );
            if ( error.statusCode == 403 || error.statusCode == 401 )
            {
              router.navigate ( [ '/', 'unauthorized' ] ) 
            }
            else
            {
              router.navigate ( [ '/', 'error' ] )
            }
        } );
    }
}

@Injectable()
export class NullErrorHandler implements ErrorHandler 
{
  constructor ( )
  {
    // Null.
  }

  handleError ( error )
  {
    console.log ( `Null error hander: ${error.originalError || error}`)
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents : [
    AppComponent,
    LogoutNotificationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FontAwesomeModule,
    KeycloakAngularModule,
    HttpClientModule,
    StoreModule.forRoot ( [ ] ),
    environment.production ? [ ] : StoreDevtoolsModule.instrument ( { maxAge: 25, logOnly: false } ),
    EffectsModule.forRoot ( [ ] ),
    NgbModule,
    SharedModule,
    NgxSpinnerModule,
    CalendarModule.forRoot ( {
      provide: DateAdapter,
      useFactory: adapterFactory
    } ),
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    BusyService,
    {
      provide: KeycloakService,
      useValue: keycloakService,
    },
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: SpinnerInterceptor, 
      multi: true,
      deps: [ BusyService ]
    },
    { 
      provide: ErrorHandler, 
      useClass: AppErrorHandler 
    }
  ]
})
export class AppModule implements DoBootstrap 
{
  async ngDoBootstrap ( app ) 
  {
    const { keycloak } = environment;

    try 
    {
      // Iniialise keycloak
      await keycloakService.init ( { config: keycloak, initOptions: { onLoad: 'login-required', checkLoginIframe: false }, 
                                     bearerExcludedUrls: [ ], loadUserProfileAtStartUp: true } );
      
      // let token = await keycloakService.getToken();
      //  console.log(token);

      // Furnish sentry with user details, if sentry is configured.
      if ( environment.sentry_url != null )
      {
        let profile = await keycloakService.loadUserProfile ( );
        Sentry.configureScope ( function ( scope )
        {
          scope.setUser( { id: profile.id, email: profile.email, username: profile.username } );
        } );
      }

      // Bootstrap the application
      app.bootstrap ( AppComponent );
    } 
    catch ( error ) 
    {
      console.error ( 'Keycloak init failed', error );
    }
  }
}
