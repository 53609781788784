import { Component, OnInit } from '@angular/core';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unauthorized-error',
  templateUrl: './unauthorized-error.component.html',
  styleUrls: ['./unauthorized-error.component.scss']
})
export class UnauthorizedErrorComponent implements OnInit {

  readonly faHome = faHome;
  
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goHome ( )
  {
    this.router.navigate ( [ 'home' ] );
  }
}
