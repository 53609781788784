import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of, forkJoin } from 'rxjs';
import { switchMap, mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { GET_SELF, GetSelf, GetSelfSuccess, GetSelfFailure, GetUsers, GET_USERS, GetUsersSuccess, 
         GetUsersFailure, CreateSite, CREATE_SITE, CreateSiteSuccess, CreateSiteFailure, DeleteSite, 
         DELETE_SITE, DeleteSiteSuccess, DeleteSiteFailure, GetCentralSites, GET_CENTRAL_SITES, 
         GetCentralSitesSuccess, GetCentralSitesFailure, CreateUser, CREATE_USER, 
         CreateUserSuccess, CreateUserFailure, DeleteUser, DELETE_USER, 
         DeleteUserFailure, DeleteUserSuccess, SendPasswordResetEmail, SEND_PASSWORD_RESET_EMAIL, 
         SendPasswordResetEmailSuccess, SendPasswordResetEmailFailure, GenerateUUIDs, GENERATE_UUIDS, 
         GenerateUUIDsSuccess, GenerateUUIDsFailure, GetDrugs, GET_DRUGS, GetDrugsSuccess, GetDrugsFailure, 
         GeneratePatientReport, GENERATE_PATIENT_REPORT, GeneratePatientReportSuccess, GeneratePatientReportFailure, 
         GetSymptoms, GET_SYMPTOMS, GetSymptomsSuccess, GetSymptomsFailure, UpdateSite, UPDATE_SITE, 
         UpdateSiteSuccess, UpdateSiteFailure, GetRoles, GET_ROLES, GetRolesSuccess, GetRolesFailure, 
         UPDATE_USER, UpdateUser, UpdateUserSuccess, UpdateUserFailure, 
         GENERATE_PATIENT_REPORT_SUCCESS, GetDiagnoses, GET_DIAGNOSES, GetDiagnosesSuccess, 
         GetDiagnosesFailure, UploadFile, UPLOAD_FILE, UploadFileSuccess, UploadFileFailure, DownloadFile, 
         DOWNLOAD_FILE, DownloadFileSuccess, DownloadFileFailure, DOWNLOAD_FILE_SUCCESS } from './shared.actions';
import { environment } from 'src/environments/environment';
import { Site } from '../utils/Site';
import { KeycloakService } from 'keycloak-angular';
import { User } from '../utils/User';
import { Central } from '../utils/Central';
import { List } from 'immutable';
import { DrugDefinition } from '../utils/DrugDefinition';
import { Symptom } from '../utils/Symptom';
import { Role } from '../utils/Role';
import { saveAs } from 'file-saver';
import { DiagnosisDefinition } from '../utils/DiagnosisDefinition';
import { Group, GROUP_CHIEF_PD } from '../utils/Group';
import { CurrentUser } from '../utils/CurrentUser';
import * as MimeTypes from 'mime-types'

@Injectable ( )
export class SharedEffects
{
    constructor ( private http: HttpClient, private keycloak: KeycloakService, private actions: Actions )
    {
        // Null.
    }

    @Effect ( )
    GenerateUUIDsEffect: Observable<Action> = this.actions.pipe ( ofType<GenerateUUIDs> ( GENERATE_UUIDS ),
        switchMap ( action => of ( action ) ),  // Create our own observable, if there is a failure this will be completed not the parent effect observable 
            concatMap ( action => this.http.get<string[]> ( `${environment.api}/uuid/` ) ), // Make API request
                map ( ( uuids: string[] ) => new GenerateUUIDsSuccess ( uuids ) ), // Trigger success action
                catchError ( ( error: any ) => of ( new GenerateUUIDsFailure ( error.status, error.statusText ) ) ) // Catch any errors and trigger failure action
     );

    @Effect ( )
    CreateSiteEffect: Observable<Action> = this.actions.pipe ( ofType<CreateSite> ( CREATE_SITE ),
        switchMap ( action => of ( action ) ),  // Create our own observable, if there is a failure this will be completed not the parent effect observable 
            map ( action => {
                let formData = new FormData ( );
                formData.append ( 'name', action.name );
                formData.append ( 'telephone', action.telephone );
                formData.append ( 'email', action.email );
                formData.append ( 'address1', action.address1 );
                if ( action.address2 != null && action.address2.length > 0 )
                {
                    formData.append ( 'address2', action.address2 );
                }
                formData.append ( 'county', action.county );
                formData.append ( 'postCode', action.postCode );
                formData.append ( 'logo', action.logo, action.logo.name );
                return formData;
            } ),
            mergeMap ( formData => this.http.post<any> ( `${environment.api}/sites/`, formData ) ), // Make API request ),
                map ( res  => 
                    {
                        // force refresh user token
                        this.keycloak.updateToken(-1);

                        // Convert from wire format to internal format
                        return new Site ( ).setId ( res.site ).setGroup ( res.group ).setName ( res.name ).setTelephone ( res.telephone ).
                                            setEmail ( res.email ).setAddress1 ( res.address1 ).setAddress2 ( res.address2 ).
                                            setCounty ( res.county ).setPostCode ( res.postCode );
                    } ),
                map ( ( site: Site ) => new CreateSiteSuccess ( site ) ), // Trigger success action
                catchError ( ( error: any ) => of ( new CreateSiteFailure ( error.status, error.statusText ) ) ) // Catch any errors and trigger failure action
     );

    @Effect ( )
    UpdateSiteEffect: Observable<Action> = this.actions.pipe ( ofType<UpdateSite> ( UPDATE_SITE ),
        switchMap ( action => of ( action ) ),  // Create our own observable, if there is a failure this will be completed not the parent effect observable 
            map ( action => {
                let formData = new FormData ( );
                formData.append ( 'group', action.group );
                formData.append ( 'id', action.id );
                formData.append ( 'name', action.name );
                formData.append ( 'telephone', action.telephone );
                formData.append ( 'email', action.email );
                formData.append ( 'address1', action.address1 );
                if ( action.address2 != null && action.address2.length > 0 )
                {
                    formData.append ( 'address2', action.address2 );
                }
                formData.append ( 'county', action.county );
                formData.append ( 'postCode', action.postCode );
                if ( action.logo != null )
                {
                    formData.append ( 'logo', action.logo, action.logo.name );
                }
                return formData;
            } ),
            mergeMap ( formData => this.http.put<any> ( `${environment.api}/sites/`, formData ) ), // Make API request ),
                map ( res  => // Convert from wire format to internal format
                    {
                        return new Site ( ).setId ( res.site ).setGroup ( res.group ).setName ( res.name ).setTelephone ( res.telephone ).
                                            setEmail ( res.email ).setAddress1 ( res.address1 ).setAddress2 ( res.address2 ).
                                            setCounty ( res.county ).setPostCode ( res.postCode );
                    } ),
                map ( ( site: Site ) => new UpdateSiteSuccess ( site ) ), // Trigger success action
                catchError ( ( error: any ) => of ( new UpdateSiteFailure ( error.status, error.statusText ) ) ) // Catch any errors and trigger failure action
     );

    @Effect ( )
    DeleteSiteEffect: Observable<Action> = this.actions.pipe ( ofType<DeleteSite> ( DELETE_SITE ),
        switchMap ( action => of ( action ) ),  // Create our own observable, if there is a failure this will be completed not the parent effect observable 
            concatMap ( action => this.http.delete ( `${environment.keycloak.url}/admin/realms/${environment.keycloak.realm}/groups/${action.id}`, 
                                                     { observe: 'response',  responseType: 'text' } ).pipe ( map ( resp => [ action, resp ] ) ) ), // Make API request
                map ( ([action, response])  => // Convert from wire format to internal format
                    {
                        return (<DeleteSite>action).id;
                    } ),
                map ( ( id: string ) => new DeleteSiteSuccess ( id ) ), // Trigger success action
                catchError ( ( error: any ) => { return of ( new DeleteSiteFailure ( error.status, error.statusText ) ) } ) // Catch any errors and trigger failure action
    );

    @Effect ( )
    GetCentralSitesEffect: Observable<Action> = this.actions.pipe ( ofType<GetCentralSites> ( GET_CENTRAL_SITES ),
        switchMap ( action => of ( action ) ),  // Create our own observable, if there is a failure this will be completed not the parent effect observable 
            mergeMap ( action => this.http.get<any> ( `${environment.api}/sites/`) ), // Make API request
                map ( groups => {
                    let sites = new Array<Site> ( );
                    for ( let group of groups.sites.groups )
                    {
                        sites.push ( new Site ( ).setId ( group.site ).setName ( group.name ).setGroup ( group.group ).setTelephone ( group.telephone ).
                                                  setEmail ( group.email ).setAddress1 ( group.address1 ).setAddress2 ( group.address2 ).
                                                  setCounty ( group.county ).setPostCode ( group.postCode ) );
                    }
                    let rootGroupId = groups.root_group;
                       
                    return [sites, rootGroupId] as const;
                } ),
                map ( ([sites, rootGroupId]) => new GetCentralSitesSuccess ( rootGroupId, sites ) ), // Trigger success action
                catchError ( ( error: any ) => of ( new GetCentralSitesFailure ( error.status, error.statusText ) ) ) // Catch any errors and trigger failure action
     );

    @Effect ( )
    GetRolesEffect: Observable<Action> = this.actions.pipe ( ofType<GetRoles> ( GET_ROLES ),
        switchMap ( action => of ( action ) ),  // Create our own observable, if there is a failure this will be completed not the parent effect observable 
            mergeMap ( action => this.http.get<any[]> ( `${environment.keycloak.url}/admin/realms/${environment.keycloak.realm}/roles/` ) ), // Make API request
                map ( roles => {
                    let res: Role[] = [];
                    let app: Role;
                    let central: Role;
                    let medic_ci: Role;
                    let medic_pi: Role;
                    let rater: Role;
                    let system: Role;
                    let pharmacy: Role;
                    for ( let role of roles )
                    {
                        // Crude method to eliminate the keycloak built in roles
                        if ( role.description.startsWith('${') == false )
                        {
                            if ( role.name == 'app_user' )
                            {
                                app = new Role ( ).setId ( role.id ).setName ( role.name ).setDescription ( role.description ).setIsTopLevelRole ( false );
                                res.push ( app );
                            }
                            else if ( role.name == 'system_admin' )
                            {
                                system = new Role ( ).setId ( role.id ).setName ( role.name ).setDescription ( role.description )
                                                     .setTitle ( 'System Administrator' ).setIsTopLevelRole ( true );
                                res.push ( system );
                            }
                            else if ( role.name == 'central_admin' )
                            {
                                central = new Role ( ).setId ( role.id ).setName ( role.name ).setDescription ( role.description )
                                                      .setTitle ( 'Central Administrator' ).setIsTopLevelRole ( true );
                                res.push ( central );
                            }
                            else if ( role.name == 'blinded_medic_ci' )
                            {
                                medic_ci = new Role ( ).setId ( role.id ).setName ( role.name ).setDescription ( role.description )
                                                       .setTitle ( 'Central Investigator' ).setIsTopLevelRole ( true );
                                res.push ( medic_ci );
                            }
                            else if ( role.name == 'blinded_medic_pi' )
                            {
                                medic_pi = new Role ( ).setId ( role.id ).setName ( role.name ).setDescription ( role.description )
                                                       .setTitle ( 'Principal Investigator (Site)' ).setIsTopLevelRole ( true );
                                res.push ( medic_pi );
                            }
                            else if ( role.name == 'blinded_rater' )
                            {
                                rater = new Role ( ).setId ( role.id ).setName ( role.name ).setDescription ( role.description )
                                                    .setTitle ( 'Rater (Site)' ).setIsTopLevelRole ( true );
                                res.push ( rater );
                            }
                            else if ( role.name == 'unblinded_pharmacy' )
                            {
                                pharmacy = new Role ( ).setId ( role.id ).setName ( role.name ).setDescription ( role.description )
                                                       .setTitle ( 'Pharmacy' ).setIsTopLevelRole ( true );
                                res.push ( pharmacy );
                            }
                            else
                            {
                                res.push ( new Role ( ).setId ( role.id ).setName ( role.name ).setDescription ( role.description ).setIsTopLevelRole ( false ) );
                            }
                        }
                    }
                    res.sort ( ( left, right ) => { return left.Description.localeCompare ( right.Description ) } );
                    return [app, system, central, medic_ci, medic_pi, rater, pharmacy, res] as const;
                } ),
                map ( ([app, system, central, medic_ci, medic_pi, rater, pharmacy, res]) => new GetRolesSuccess ( res, app, system, central, medic_ci, medic_pi, rater, pharmacy ) ), // Trigger success action
                catchError ( ( error: any ) => of ( new GetRolesFailure ( error.status, error.statusText ) ) ) // Catch any errors and trigger failure action
     );
     
    @Effect ( )
    CreateUserEffect: Observable<Action> = this.actions.pipe ( ofType<CreateUser> ( CREATE_USER ),
        switchMap ( action => of ( action ) ),  // Create our own observable, if there is a failure this will be completed not the parent effect observable 
        concatMap ( action => this.http.post ( `${environment.keycloak.url}/admin/realms/${environment.keycloak.realm}/users/`, 
                                                { username: action.email, email: action.email, firstName: action.firstName, lastName: action.lastName, enabled: true, realmRoles: [action.role.Id] },
                                                { observe: 'response',  responseType: 'text' } ).pipe ( map ( resp => [ action, resp ] as const ) ) ), // Make API request
        map ( ([action, response])  => // Convert from wire format to internal format
        {
            let loc = (<HttpResponse<string>>response).headers.get ( 'Location' );
            if ( loc != null && loc.length > 0 )
            {
                loc = loc.substring ( `${environment.keycloak.url}/admin/realms/${environment.keycloak.realm}/users/`.length );
            }
        
            return [ action, new User ( ).setId ( loc ).
                                        setEmail ( action.email ).
                                        setFirstName ( action.firstName ).
                                        setLastName ( action.lastName ).
                                        setGroups ( List ( action.groups ) ).
                                        setRoles ( List ( [ action.role.Name ] ) ) ] as const;
        } ),
        mergeMap ( ([action, user])  => {
            let obs = [];
            // Sites
            action.groups.forEach( group => {
                obs.push ( this.http.put<string> ( `${environment.keycloak.url}/admin/realms/${environment.keycloak.realm}/users/${user.Id}/groups/${group.Id}`, {} ) )
            });
            // Roles
            obs.push ( this.http.post<any[]> ( `${environment.keycloak.url}/admin/realms/${environment.keycloak.realm}/users/${user.Id}/role-mappings/realm`, 
                                                action.generateRolesBody ( ) ) );
            return forkJoin ( obs ).pipe ( map ( resp => user ) ) 
        }),
        map ( user => new CreateUserSuccess ( user ) ), // Trigger success action
        catchError ( ( error: any ) => of ( new CreateUserFailure ( error.status, error.statusText ) ) ) // Catch any errors and trigger failure action
    );

    @Effect ( )
    UpdateUserEffect: Observable<Action> = this.actions.pipe ( ofType<UpdateUser> ( UPDATE_USER ),
        switchMap ( action => of ( action ) ),  // Create our own observable, if there is a failure this will be completed not the parent effect observable 
        concatMap ( action => this.http.put ( `${environment.keycloak.url}/admin/realms/${environment.keycloak.realm}/users/${action.userId}`, 
                                                { username: action.email, email: action.email, firstName: action.firstName, lastName: action.lastName, enabled: true, realmRoles: [action.role.Id] }, 
                                                { observe: 'response',  responseType: 'text' } ).pipe ( map ( resp => action ) ) ), // Make API request
        map ( action  => // Convert from wire format to internal format
        {
            return [action, new User ( ).setId ( action.userId ).
                                         setEmail ( action.email ).
                                         setFirstName ( action.firstName ).
                                         setLastName ( action.lastName ).
                                         setGroups ( List ( action.groups ) ).
                                         setRoles ( List ( [ action.role.Name ] ) )] as const;
        } ),
        mergeMap ( ([action, user])  => {
            let obs = [];
            // Sites
            action.groups.forEach( group => {
                obs.push ( this.http.put<string> ( `${environment.keycloak.url}/admin/realms/${environment.keycloak.realm}/users/${user.Id}/groups/${group.Id}`, {} ) )
            });
            // Roles
            obs.push ( this.http.post<any[]> ( `${environment.keycloak.url}/admin/realms/${environment.keycloak.realm}/users/${user.Id}/role-mappings/realm`, 
                                                 action.generateRolesBody ( ) ) );
            return forkJoin ( obs ).pipe ( map ( resp => user ) ) 
        }),

        map ( user => new UpdateUserSuccess ( user ) ), // Trigger success action
        catchError ( ( error: any ) => of ( new UpdateUserFailure ( error.status, error.statusText ) ) ) // Catch any errors and trigger failure action
    );

    @Effect ( )
    DeleteUserEffect: Observable<Action> = this.actions.pipe ( ofType<DeleteUser> ( DELETE_USER ),
        switchMap ( action => of ( action ) ),  // Create our own observable, if there is a failure this will be completed not the parent effect observable 
            concatMap ( action => this.http.delete ( `${environment.keycloak.url}/admin/realms/${environment.keycloak.realm}/users/${action.id}`, 
                                                     { observe: 'response',  responseType: 'text' } ).pipe ( map ( resp => [ action, resp ] ) ) ), // Make API request
                map ( ([action, response])  => // Convert from wire format to internal format
                    {
                        return (<DeleteUser>action).id;
                    } ),
                map ( ( id: string ) => new DeleteUserSuccess ( id ) ), // Trigger success action
                catchError ( ( error: any ) => of ( new DeleteUserFailure ( error.status, error.statusText ) ) ) // Catch any errors and trigger failure action
    );

     @Effect ( )
     GetUsersEffect: Observable<Action> = this.actions.pipe ( ofType<GetUsers> ( GET_USERS ),
         switchMap ( action => of ( action ) ),  // Create our own observable, if there is a failure this will be completed not the parent effect observable 
             mergeMap ( action => this.http.get<any[]> ( `${environment.keycloak.url}/admin/realms/${environment.keycloak.realm}/users?max=300`) ), // Make API request
                 map ( ( users: any[] ) => // Convert from wire format to internal format
                     {
                        let out: User[] = [];
                        for ( let user of users )
                        {
                            out.push ( new User ( ).setId ( user.id ).setFirstName ( user.firstName ).setLastName ( user.lastName ).setEmail ( user.email ) );
                        }
                        return out;
                     } ),
                mergeMap ( users => { 
                    let out: Observable<readonly [User, any, any]>[] = [];
                    for ( let user of users )
                    {
                       out.push ( forkJoin ( this.http.get<any> ( `${environment.keycloak.url}/admin/realms/${environment.keycloak.realm}/users/${user.Id}/groups/` ),
                                             this.http.get<any> ( `${environment.keycloak.url}/admin/realms/${environment.keycloak.realm}/users/${user.Id}/role-mappings/realm/` ) ).
                            pipe ( map ( ([groups, role_mappings]) =>  [ user, groups, role_mappings ] ) ) );
                    }
                    return forkJoin ( out );
                 } ),
                 map ( users => {
                     
                    let out = new Array<User> ( );
                    for ( let [user, groups, role_mappings] of users )
                    {
                        let userGroups = new Array<Group> ( );
                        let roles = new Array<string> ( );

                        for ( let group of groups )
                        {
                            userGroups.push ( new Group ( ).setId ( group.id ).setName ( group.name ).setPath ( group.path ) );
                        }

                        for ( let role of role_mappings )
                        {
                            // Crude method to eliminate the keycloak built in roles
                            if ( role.description.startsWith('${') == false )
                            {
                                roles.push ( role.name );
                            }
                        }

                        out.push ( user.setGroups ( List ( userGroups ) ).setRoles ( List ( roles ) ) );
                    }

                    return out;
                 } ),
                 map ( users => new GetUsersSuccess ( users ) ), // Trigger success action
                 catchError ( ( error: any ) => of ( new GetUsersFailure ( error.status, error.statusText ) ) ) // Catch any errors and trigger failure action
      );

    @Effect ( )
    GetSelfEffect: Observable<Action> = this.actions.pipe ( ofType<GetSelf> ( GET_SELF ),
        switchMap ( action => of ( action.token ) ),  // Create our own observable, if there is a failure this will be completed not the parent effect observable 
                map ( token => [ token.realm_access.roles as string[], token.groups as any[], 
                                 new CurrentUser ( ).setId ( token.sub ).
                                                     setFirstName ( token.given_name ).
                                                     setLastName ( token.family_name ).
                                                     setEmail ( token.email ) ] as const ),
                map ( ([roles, groups, user]) => {
                    let groupObjects = new Array<Group> ( );
                    for ( let group of groups )
                    {
                        groupObjects.push ( new Group ( ).setId ( group.group ).setName ( group.name ).setPath ( group.path ) );
                    }

                    return [roles, groupObjects, user] as const;
                } ),
                map ( ([roles, groups, user]) => user.setRoles ( List ( roles ) ).setGroups ( List ( groups ) ) ),
                
                map ( user => new GetSelfSuccess ( user ) ), // Trigger success action
                catchError ( ( error: any ) => of ( new GetSelfFailure ( error.status, error.statusText ) ) ) // Catch any errors and trigger failure action
     );

    @Effect ( )
    SendPasswordResetEffect: Observable<Action> = this.actions.pipe ( ofType<SendPasswordResetEmail> ( SEND_PASSWORD_RESET_EMAIL ),
        switchMap ( action => of ( action ) ),  // Create our own observable, if there is a failure this will be completed not the parent effect observable 
            mergeMap ( action => this.http.put ( `${environment.keycloak.url}/admin/realms/${environment.keycloak.realm}/users/${action.userId}/execute-actions-email`, 
                                                    ["UPDATE_PASSWORD"], { observe: 'response',  responseType: 'text' } ) ), // Make API request
                map ( res  => // Convert from wire format to internal format
                {
                    return '';
                } ),
                map ( ( id: string ) => new SendPasswordResetEmailSuccess ( ) ), // Trigger success action
                catchError ( ( error: any ) => of ( new SendPasswordResetEmailFailure ( error.status, error.statusText ) ) ) // Catch any errors and trigger failure action
    );

    @Effect ( )
    GetDiagnosesEffect: Observable<Action> = this.actions.pipe ( ofType<GetDiagnoses> ( GET_DIAGNOSES ),
        switchMap ( action => of ( action ) ),  // Create our own observable, if there is a failure this will be completed not the parent effect observable 
            mergeMap ( action => this.http.get<any> ( `${environment.api}/diagnoses/${action.term}`) ), // Make API request
                map ( DiagnosisDefinition.fromJSON ),
                map ( ( diagnoses: DiagnosisDefinition[] ) => new GetDiagnosesSuccess ( diagnoses ) ), // Trigger success action
                catchError ( ( error: any ) => of ( new GetDiagnosesFailure ( error.status, error.statusText ) ) ) // Catch any errors and trigger failure action
     );

    @Effect ( )
    GetDrugsEffect: Observable<Action> = this.actions.pipe ( ofType<GetDrugs> ( GET_DRUGS ),
        switchMap ( action => of ( action ) ),  // Create our own observable, if there is a failure this will be completed not the parent effect observable 
            mergeMap ( action => this.http.get<any> ( `${environment.api}/drugs/`) ), // Make API request
                map ( DrugDefinition.fromJSON ),
                map ( ( drugs: DrugDefinition[] ) => new GetDrugsSuccess ( drugs ) ), // Trigger success action
                catchError ( ( error: any ) => of ( new GetDrugsFailure ( error.status, error.statusText ) ) ) // Catch any errors and trigger failure action
     );

    @Effect ( )
    GetSymptomsEffect: Observable<Action> = this.actions.pipe ( ofType<GetSymptoms> ( GET_SYMPTOMS ),
        switchMap ( action => of ( action ) ),  // Create our own observable, if there is a failure this will be completed not the parent effect observable 
            mergeMap ( action => this.http.get<any> ( `${environment.api}/symptoms/`) ), // Make API request
                map ( Symptom.fromJSONForList ),
                map ( ( symptoms: Symptom[] ) => new GetSymptomsSuccess ( symptoms ) ), // Trigger success action
                catchError ( ( error: any ) => of ( new GetSymptomsFailure ( error.status, error.statusText ) ) ) // Catch any errors and trigger failure action
    );

    @Effect ( )
    GeneratePatientReportEffect: Observable<Action> = this.actions.pipe ( ofType<GeneratePatientReport> ( GENERATE_PATIENT_REPORT ),
        switchMap ( action => of ( action ) ),  // Create our own observable, if there is a failure this will be completed not the parent effect observable 
            mergeMap ( action => this.http.get ( `${environment.api}/pdf/${action.patient}/${action.report}`, {responseType: 'blob'} ).pipe ( map ( resp => [ action.patient, action.patientId, action.patientSiteId, action.report, resp ] ) ) ), // Make API request
                map ( res => {
                    let patient = <string>res [ 0 ];
                    let patientId = <string>res [ 1 ];
                    let siteId = <string>res [ 2 ];
                    let report = <string>res [ 3 ];
                    let raw = <Blob>res [ 4 ];
                    return new GeneratePatientReportSuccess ( patient, patientId, siteId, report, raw ) 
                } ), // Trigger success action
                catchError ( ( error: any ) => { return of ( new GeneratePatientReportFailure ( error.status, error.statusText ) ) } ) // Catch any errors and trigger failure action
     );


    @Effect ( { dispatch: false } )
    GeneratePatientReportSuccessEffect: Observable<Action> = this.actions.pipe ( ofType<GeneratePatientReportSuccess> ( GENERATE_PATIENT_REPORT_SUCCESS ),
        map ( action => {
            let siteId = action.patientSiteId? action.patientSiteId : 'UnknownSite';
            let partId = action.patientId ? action.patientId : 'ID-TBA';
            return saveAs ( action.raw, `${siteId}-${partId}_${action.report}.pdf` );
        } )
     );

     @Effect ( )
    UploadFileEffect: Observable<Action> = this.actions.pipe ( ofType<UploadFile> ( UPLOAD_FILE ),
        switchMap ( action => of ( action ) ),  // Create our own observable, if there is a failure this will be completed not the parent effect observable 
            map ( action => {
                let formData = new FormData ( );
                formData.append ( 'site', action.site );
                formData.append ( 'participant', action.participant );
                formData.append ( 'upload', action.file, action.file.name );
                return formData;
            } ),
            mergeMap ( formData => this.http.post<any> ( `${environment.api}/uploads/`, formData ) ), // Make API request
                map ( res => res['id'] ),
                map ( id => new UploadFileSuccess ( id ) ), // Trigger success action
                catchError ( ( error: any ) => of ( new UploadFileFailure ( error.status, error.statusText ) ) ) // Catch any errors and trigger failure action
     );

    @Effect ( )
    DownloadFileEffect: Observable<Action> = this.actions.pipe ( ofType<DownloadFile> ( DOWNLOAD_FILE ),
        switchMap ( action => of ( action ) ),  // Create our own observable, if there is a failure this will be completed not the parent effect observable 
            mergeMap ( action => this.http.get ( `${environment.api}/uploads/${action.site}/${action.participant}/${action.file}/`, {responseType: 'blob'} ).pipe ( map ( resp => [ action.site, action.participant, action.file, resp ] ) ) ), // Make API request
                map ( res => {
                    let site = <string>res [ 0 ];
                    let participant = <string>res [ 1 ];
                    let file = <string>res [ 2 ];
                    let raw = <Blob>res [ 3 ];
                    return new DownloadFileSuccess ( site, participant, file, raw ); 
                } ), // Trigger success action
                catchError ( ( error: any ) => { return of ( new DownloadFileFailure ( error.status, error.statusText ) ) } ) // Catch any errors and trigger failure action
    );


    @Effect ( { dispatch: false } )
    DownloadFileSuccessEffect: Observable<Action> = this.actions.pipe ( ofType<DownloadFileSuccess> ( DOWNLOAD_FILE_SUCCESS ),
        map ( action => {
            let ext = MimeTypes.extension(action.raw.type);
            return saveAs ( action.raw, 'upload.' + (ext == false ? 'pdf' : ext));
        } )
    );
}