import { Component, OnInit } from '@angular/core';
import { faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  readonly faHome = faHome;
  
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goHome ( )
  {
    this.router.navigate ( [ 'home' ] );
  }
}
