import { Map } from 'immutable';

export abstract class ImmutableClass
{
    /**
     * Underlying immutable map container
     */
    protected i: Map<string, any>;

    /**
     * Constructor 
     * 
     * @param i existing map of attributes
     */
    public constructor ( i: any = undefined )
    {
        this.i = Map<string, any> ( i || { } );
    }
}