import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SharedService } from './shared.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { DrugDefinition } from '../utils/DrugDefinition';
import { Symptom } from '../utils/Symptom';


@Injectable()
export class SymptomsResolver implements Resolve<Symptom[]>
{
    constructor ( private service: SharedService )
    {
        // Null.
    }

    resolve ( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<Symptom[]> 
    {
        return this.service.getSymptoms ( ).pipe ( take ( 1 ) );
    }
}