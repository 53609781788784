import { ImmutableClass } from './ImmutableClass';
import { Map } from 'immutable';

export const GROUP_CHIEF_PD = 'CHIEF-PD';

export class Group extends ImmutableClass
{
    /**
     * Constructor 
     * @param i existing map of attributes
     */
    public constructor ( i: Map<string, any> = undefined )
    {
        super ( i );
    }

    public get Id ( ): string
    {
        return <string>this.i.get ( 'id' );
    }
    
    public setId ( id: string ): Group
    {
        return new Group ( this.i.set ( 'id', id ) );
    }

    public get Name ( ): string
    {
        return <string>this.i.get ( 'name' );
    }

    public setName ( name: string ): Group
    {
        return new Group ( this.i.set ( 'name', name ) );
    }

    public get Path ( ): string
    {
        return <string>this.i.get ( 'path' );
    }

    public setPath ( path: string ): Group
    {
        return new Group ( this.i.set ( 'path', path ) );
    }
}