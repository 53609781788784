import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { Store } from '@ngrx/store';
import { SharedState } from './shared.state';
import { take, filter, map, tap } from 'rxjs/operators';
import { GetUsers, CreateSite, UpdateSite, DeleteSite, DeleteUser, GetCentralSites, SendPasswordResetEmail, CreateUser, GenerateUUIDs, ConsumeUUID, GetDrugs, GeneratePatientReport, GetSymptoms, GetRoles, UpdateUser, GetDiagnoses, UploadFile, DownloadFile, GetSelf } from './shared.actions';
import { Site } from '../utils/Site';
import { User } from '../utils/User';
import { DrugDefinition } from '../utils/DrugDefinition';
import { Symptom } from '../utils/Symptom';
import { Role } from '../utils/Role';
import { DiagnosisDefinition } from '../utils/DiagnosisDefinition';
import { CurrentUser } from '../utils/CurrentUser';
import { Group } from '../utils/Group';

interface AppState
{
    shared: SharedState;
}

@Injectable ( )
export class SharedService
{
    constructor ( private store: Store<AppState> )
    {
        // Null.
    }

    getUUID ( ): Observable<string>
    {
        // Request next UUID batch if depleted
        this.store.select ( state => state.shared.UUIDs ).subscribe ( uuids => {
            if ( uuids == null || uuids.length == 0 )
            {
                this.store.dispatch ( new GenerateUUIDs ( ) );
            }
        } );

        // Take from the top
        return this.store.select ( state => state.shared.UUIDs ).pipe ( 
            filter ( uuids => !!uuids ), 
            filter ( uuids => uuids.length > 0 ),
            map ( uuids => {
            let uuid = uuids[0];
            this.store.dispatch ( new ConsumeUUID ( ) );
            return uuid;
        } ) );
    }

    createSite ( name: string, telephone: string, email: string, address1: string, 
                 address2: string, county: string, postCode: string, logo: File ) : Observable<Site[]>
    {
        this.store.dispatch ( new CreateSite ( name, telephone, email, address1, address2, county, postCode, logo ) );

        let sites =  this.store.select ( state => state.shared ).pipe (
            filter ( shared => shared != null && shared.Sites != null ),
            map ( shared => shared.Sites ),
            map ( sites => sites.toArray ( ) ));
        return sites;
    }

    updateSite ( group: string, id: string, name: string, telephone: string, email: string, 
                 address1: string, address2: string, county: string, postCode: string, logo: File ) : Observable<Site[]>
    {
        this.store.dispatch ( new UpdateSite ( group, id, name, telephone, email, address1, address2, county, postCode, logo ) );

        let sites =  this.store.select ( state => state.shared ).pipe (
            filter ( shared => shared != null && shared.Sites != null ),
            map ( shared => shared.Sites ),
            map ( sites => sites.toArray ( ) ) );
        return sites;
    }

    deleteSite ( id: string ) : Observable<Site[]>
    {
        this.store.dispatch ( new DeleteSite ( id ) );

        let sites =  this.store.select ( state => state.shared ).pipe (
            filter ( shared => shared != null && shared.Sites != null ),
            map ( shared => shared.Sites ),
            map ( sites => sites.toArray ( ) ));
        return sites;
    }

    getSites ( ) : Observable<Site[]>
    {
        this.store.pipe ( take ( 1 ) ).subscribe ( store => {
            if ( store.shared.Sites == null )
            {
                this.store.dispatch ( new GetCentralSites ( ) );
            }
        } );
 
        let sites =  this.store.select ( state => state.shared ).pipe (
            filter ( shared => shared != null && shared.Sites != null ),
            map ( sites => sites.Sites.toArray ( ) ) );
        return sites;
    }

    getRootGroupId ( ) : Observable<string>
    {
        let root =  this.store.select ( state => state.shared ).pipe (
            filter ( shared => shared != null && shared.RootGroupId != null ),
            map ( shared => shared.RootGroupId ) );
        return root;
    }

    // getCentral ( ) : Observable<{id: string, central: Central[]}>
    // {
    //     this.store.pipe ( take ( 1 ) ).subscribe ( store => {
    //         if ( store.shared.Central == null )
    //         {
    //             this.store.dispatch ( new GetCentralSites ( ) );
    //         }
    //     } );
 
    //     let central =  this.store.select ( state => state.shared ).pipe (
    //         filter ( shared => shared != null && shared.Central != null ),
    //         map ( central => {return {id: central.CentralGroupId, central: central.Central.toArray ( )}} ) );
    //     return central;
    // }

    getRoles ( ) : Observable<Role[]>
    {
        this.store.pipe ( take ( 1 ) ).subscribe ( store => {
            if ( store.shared.Roles == null )
            {
                this.store.dispatch ( new GetRoles ( ) );
            }
        } );
 
        let roles =  this.store.select ( state => state.shared ).pipe (
            filter ( shared => shared != null && shared.Roles != null ),
            map ( shared => shared.Roles.toArray ( ) ) );
        return roles;
    }

    getSystemAdminRole ( ) : Observable<Role>
    {
        this.store.pipe ( take ( 1 ) ).subscribe ( store => {
            if ( store.shared.SystemAdminRole == null )
            {
                this.store.dispatch ( new GetRoles ( ) );
            }
        } );
 
        let role =  this.store.select ( state => state.shared ).pipe (
            filter ( shared => shared != null && shared.SystemAdminRole != null ),
            map ( shared => shared.SystemAdminRole ) );
        return role;
    }

    getCentralAdminRole ( ) : Observable<Role>
    {
        this.store.pipe ( take ( 1 ) ).subscribe ( store => {
            if ( store.shared.CentralAdminRole == null )
            {
                this.store.dispatch ( new GetRoles ( ) );
            }
        } );
 
        let role =  this.store.select ( state => state.shared ).pipe (
            filter ( shared => shared != null && shared.CentralAdminRole != null ),
            map ( shared => shared.CentralAdminRole ) );
        return role;
    }

    getAppRole ( ) : Observable<Role>
    {
        this.store.pipe ( take ( 1 ) ).subscribe ( store => {
            if ( store.shared.AppRole == null )
            {
                this.store.dispatch ( new GetRoles ( ) );
            }
        } );
 
        let role =  this.store.select ( state => state.shared ).pipe (
            filter ( shared => shared != null && shared.AppRole != null ),
            map ( shared => shared.AppRole ) );
        return role;
    }

    createUser ( email: string, firstName: string, lastName: string, groups: Group[], role: Role ) : Observable<User[]>
    {
        this.store.dispatch ( new CreateUser ( email, firstName, lastName, groups, role ) );

        let users =  this.store.select ( state => state.shared ).pipe (
            filter ( shared => shared != null && shared.Users != null ),
            map ( shared => shared.Users ),
            map ( users => users.toArray ( ) ) );
        return users;
    }

    updateUser ( userId: string, email: string, firstName: string, lastName: string, groups: Group[], role: Role ) : Observable<User[]>
    {
        this.store.dispatch ( new UpdateUser ( userId, email, firstName, lastName, groups, role ) );

        let users =  this.store.select ( state => state.shared ).pipe (
            filter ( shared => shared != null && shared.Users != null ),
            map ( shared => shared.Users ),
            map ( users => users.toArray ( ) ) );
        return users;
    }

    deleteUser ( id: string ) : Observable<User[]>
    {
        this.store.dispatch ( new DeleteUser ( id ) );

        let users =  this.store.select ( state => state.shared ).pipe (
            filter ( shared => shared != null && shared.Users != null ),
            map ( shared => shared.Users ),
            map ( users => users.toArray ( ) ) );
        return users;
    }

    getUsers ( ) : Observable<User[]>
    {
        this.store.pipe ( take ( 1 ) ).subscribe ( store => {
            if ( store.shared.Users == null )
            {
                this.store.dispatch ( new GetUsers ( ) );
            }
        } );
 
        let users =  this.store.select ( state => state.shared ).pipe (
            filter ( shared => shared != null && shared.Users != null ),
            map ( shared => shared.Users ),
            map ( users => users.toArray ( ) ) );
        return users;
    }

    getSelf ( token: any ) : Observable<CurrentUser>
    {
        this.store.pipe ( take ( 1 ) ).subscribe ( store => {
            if ( store.shared.Self == null )
            {
                this.store.dispatch ( new GetSelf ( token ) );
            }
        } );
 
        let self =  this.store.select ( state => state.shared ).pipe (
            filter ( shared => shared != null && shared.Self != null ),
            map ( shared => shared.Self ) );
        return self;
    }

    sendPasswordResetEmail ( user: User )
    {
        this.store.dispatch ( new SendPasswordResetEmail ( user.Id ) );
    }

    getDiagnoses ( term: string ) : Observable<DiagnosisDefinition[]>
    {
        return this.store.select ( state => state.shared.Diagnoses ).pipe ( 
            tap ( diagnoses => {
               // if ( diagnoses == null )
                {
                    this.store.dispatch ( new GetDiagnoses ( term ) );
                }
            } ),
            filter ( diagnoses => !!diagnoses ),
            map ( diagnoses => diagnoses.toArray ( ) ),
            take ( 1 )
        );
    }

    getDrugs ( ) : Observable<DrugDefinition[]>
    {
        return this.store.select ( state => state.shared.Drugs ).pipe ( 
            tap ( drugs => {
                if ( drugs == null )
                {
                    this.store.dispatch ( new GetDrugs ( ) );
                }
            } ),
            filter ( drugs => !!drugs ),
            map ( drugs => drugs.toArray ( ) )
        );
    }

    getSymptoms ( ) : Observable<Symptom[]>
    {
        return this.store.select ( state => state.shared.Symptoms ).pipe ( 
            tap ( symptons => {
                if ( symptons == null )
                {
                    this.store.dispatch ( new GetSymptoms ( ) );
                }
            } ),
            filter ( symptons => !!symptons ),
            map ( symptons => symptons.toArray ( ) )
        );
    }

    generatePatientReport ( patient: string, patientId: string, patientSiteId: string, report: string ) : Observable<Blob>
    {
        this.store.dispatch ( new GeneratePatientReport ( patient, patientId, patientSiteId, report ) );
        
        return this.store.select ( state => state.shared.PatientReport ).pipe ( 
            map ( state => {
                if ( state == null || state [ 0 ] != report )
                {
                    state = null;
                }

                return state;
            } ),
            filter ( state => !!state ),
            map ( state => state[1] ),
            take ( 1 )
        );
    }

    uploadFile ( site: string, participant: string, file: File ) : Observable<string>
    {
        this.store.dispatch ( new UploadFile ( site, participant, file ) );

        return this.store.select ( state => state.shared ).pipe (
            filter ( patient => patient != null && patient.LastUploadId != null ),
            map ( patient => patient.LastUploadId ) );
    }

    downloadFile ( site: string, participant: string, file: string ) : Observable<string>
    {
        this.store.dispatch ( new DownloadFile ( site, participant, file ) );
        return this.store.select ( state => state.shared.Download ).pipe ( 
           /* map ( state => {
                if ( state == null || state [ 0 ] != report )
                {
                    state = null;
                }

                return state;
            } ),*/
            filter ( state => !!state ),
            map ( state => state[1] ),
            take ( 1 )
        );
    }
}