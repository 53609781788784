import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { BusyService } from './busy.service'

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor 
{
    constructor ( private busy: BusyService )
    {
        // Null.
    }

    intercept ( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> 
    {
        this.busy.busy ( );
        return next.handle ( req ).pipe (
            map ( event => event ),
            catchError ( error => throwError ( error ) ),
            finalize ( ( ) => { this.busy.idle ( ) } ) );
    }
}