import { ImmutableClass } from '../utils/ImmutableClass';
import { List, Map } from 'immutable';
import { Site } from '../utils/Site';
import { User } from '../utils/User';
import { Central } from '../utils/Central';
import { DrugDefinition } from '../utils/DrugDefinition';
import { Symptom } from '../utils/Symptom';
import { Role } from '../utils/Role';
import { DiagnosisDefinition } from '../utils/DiagnosisDefinition';
import { CurrentUser } from '../utils/CurrentUser';

export class SharedState extends ImmutableClass
{
    /**
     * Constructor 
     * @param i existing map of attributes
     */
    public constructor ( i: Map<string, any> = undefined )
    {
        super ( i );
    }

    public get UUIDs ( ): string[]
    {
        return <string[]>this.i.get ( 'uuids' );
    }

    public setUUIDs ( uuids: string[] ): SharedState
    {
        return new SharedState ( this.i.set ( 'uuids', uuids ) );
    }

    public get Sites ( ): List<Site>
    {
        return <List<Site>>this.i.get ( 'sites' );
    }

    public setSites ( sites: List<Site> ): SharedState
    {
        return new SharedState ( this.i.set ( 'sites', sites ) );
    }

    public get Roles ( ): List<Role>
    {
        return <List<Role>>this.i.get ( 'roles' );
    }

    public setRoles ( roles: List<Role> ): SharedState
    {
        return new SharedState ( this.i.set ( 'roles', roles ) );
    }

    public get AppRole ( ): Role
    {
        return <Role>this.i.get ( 'appRole' );
    }

    public setAppRole ( role: Role ): SharedState
    {
        return new SharedState ( this.i.set ( 'appRole', role ) );
    }

    public get SystemAdminRole ( ): Role
    {
        return <Role>this.i.get ( 'systemAdminRole' );
    }

    public setSystemAdminRole ( role: Role ): SharedState
    {
        return new SharedState ( this.i.set ( 'systemAdminRole', role ) );
    }

    public get CentralAdminRole ( ): Role
    {
        return <Role>this.i.get ( 'centralAdminRole' );
    }

    public setCentralAdminRole ( role: Role ): SharedState
    {
        return new SharedState ( this.i.set ( 'centralAdminRole', role ) );
    }

    public get CentralInvestigatorRole ( ): Role
    {
        return <Role>this.i.get ( 'centralInvestigatorRole' );
    }

    public setCentralInvestigatorRole ( role: Role ): SharedState
    {
        return new SharedState ( this.i.set ( 'centralInvestigatorRole', role ) );
    }

    public get PrincipalInvestigatorRole ( ): Role
    {
        return <Role>this.i.get ( 'principalInvestigatorRole' );
    }

    public setPrincipalInvestigatorRole ( role: Role ): SharedState
    {
        return new SharedState ( this.i.set ( 'principalInvestigatorRole', role ) );
    }

    public get PharmacyRole ( ): Role
    {
        return <Role>this.i.get ( 'pharmacyRole' );
    }

    public setPharmacyRole ( role: Role ): SharedState
    {
        return new SharedState ( this.i.set ( 'pharmacyRole', role ) );
    }

    public get Users ( ): List<User>
    {
        return <List<User>>this.i.get ( 'users' );
    }

    public setUsers ( users: List<User> ): SharedState
    {
        return new SharedState ( this.i.set ( 'users', users ) );
    }

    public get Self ( ): CurrentUser
    {
        return <CurrentUser>this.i.get ( 'self' );
    }

    public setSelf ( self: CurrentUser ): SharedState
    {
        return new SharedState ( this.i.set ( 'self', self ) );
    }

    public get Diagnoses ( ): List<DiagnosisDefinition>
    {
        return <List<DiagnosisDefinition>>this.i.get ( 'diagnoses' );
    }

    public setDiagnoses ( diagnoses: List<DiagnosisDefinition> ): SharedState
    {
        return new SharedState ( this.i.set ( 'diagnoses', diagnoses ) );
    }

    public get Drugs ( ): List<DrugDefinition>
    {
        return <List<DrugDefinition>>this.i.get ( 'drugs' );
    }

    public setDrugs ( drugs: List<DrugDefinition> ): SharedState
    {
        return new SharedState ( this.i.set ( 'drugs', drugs ) );
    }

    public get Symptoms ( ): List<Symptom>
    {
        return <List<Symptom>>this.i.get ( 'symptoms' );
    }

    public setSymptoms ( symptoms: List<Symptom> ): SharedState
    {
        return new SharedState ( this.i.set ( 'symptoms', symptoms ) );
    }

    public get PatientReport ( ): [string, Blob]
    {
        return <[string, Blob]>this.i.get ( 'report' );
    }

    public setPatientReport ( report: [string, Blob] ): SharedState
    {
        return new SharedState ( this.i.set ( 'report', report ) );
    }

    public get LastUploadId ( ): string
    {
        return <string>this.i.get ( 'lastUploadId' );
    }

    public setLastUploadId ( id: string ): SharedState
    {
        return new SharedState ( this.i.set ( 'lastUploadId', id ) );
    }

    public get Download ( ): [string, string, string, Blob]
    {
        return <[string, string, string, Blob]>this.i.get ( 'download' );
    }

    public setDownload ( download: [string, string, string, Blob] ): SharedState
    {
        return new SharedState ( this.i.set ( 'download', download ) );
    }

    public get RootGroupId ( ) : string
    {
        return <string>this.i.get ( 'rootGroupId' );
    }

    public setRootGroupId ( rootGroupId: string ) : SharedState
    {
        return new SharedState ( this.i.set ( 'rootGroupId', rootGroupId ))
    }
}

/**
 * Default Shared State
 */
export const DEFAULT_SHARED_STATE: SharedState = new SharedState ( ) ;